var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[(_vm.warehouse)?_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"extended":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{staticStyle:{"width":"80%"},attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsWarehouseDetail',
              params: { warehouseID: _vm.$route.params.warehouseID }
            },"exact":""}},[_vm._v("Details")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsWarehouseStockList',
              params: { warehouseID: _vm.$route.params.warehouseID }
            }}},[_vm._v("Stock")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsWarehouseSettings',
              params: { warehouseID: _vm.$route.params.warehouseID }
            }}},[_vm._v("Settings")])],1),(_vm.warehouse && _vm.authLevel && _vm.authLevel === 2)?_c('v-spacer'):_vm._e(),_vm._t("controls")]},proxy:true}],null,true)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","lg":"9","xl":"10"}},[_c('h2',{staticClass:"mb-0 text-truncate primary--text"},[_vm._v(" "+_vm._s(_vm.warehouse.name)+" ")]),(_vm.warehouse.provider)?_c('div',{staticClass:"text-body2"},[_vm._v(" "+_vm._s(_vm.warehouse.provider.name)+" ")]):_vm._e()]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4","lg":"3","xl":"2"}})],1)],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"main-page-column"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('router-view',{attrs:{"warehouse-providers":_vm.warehouseProviders,"warehouse":_vm.warehouse,"current-user":_vm.currentUser,"auth-level":_vm.authLevel,"loading":_vm.$apollo.loading},on:{"refresh-warehouse":_vm.refreshWarehouse}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }