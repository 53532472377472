<template>
  <v-expansion-panels v-model="selected" multiple hover flat>
    <v-expansion-panel v-for="item in providers" :key="item.id" class="mb-1">
      <v-expansion-panel-header disable-icon-rotate>
        <v-row align="center"
          ><v-col class="py-0"
            ><span class="text-body-1 primary--text">{{
              item.name
            }}</span></v-col
          >
        </v-row>
        <template v-slot:actions>
          <v-btn icon x-small @click.stop="">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>

                <!-- <v-list-item @click="editContact(item.id)">
                  <v-list-item-title>Add Contact</v-list-item-title>
                </v-list-item> -->

                <v-list-item @click="removeItem(item.id)">
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2">
        <v-row v-if="item.providerKey">
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">
              Provider Key
            </label>
          </v-col>
          <v-col cols="12" class="pt-0">
            {{ item.providerKey }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">
              Api Key
            </label>
          </v-col>
          <v-col cols="12" class="pt-0">
            {{ item.apiKey }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">
              Email
            </label>
          </v-col>
          <v-col cols="12" class="pt-0">
            {{ item.address.email }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">
              Phone
            </label>
          </v-col>
          <v-col cols="12" class="pt-0">
            {{ item.address.phone }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">
              Website
            </label>
          </v-col>
          <v-col cols="12" class="pt-0">
            {{ item.website }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">Address</label>
          </v-col>
          <v-col
            v-if="item.address && item.address.id !== ''"
            cols="12"
            class="pt-0"
          >
            <v-row no-gutters>
              <v-col cols="12" class="pt-0">
                {{ item.address.line1 }}
              </v-col>
              <v-col cols="12" class="pt-0">
                {{ item.address.line2 }}
              </v-col>
              <v-col cols="12" class="pt-0">
                {{ item.address.postCode }}
              </v-col>
              <v-col cols="12" class="pt-0">
                {{ item.address.city }}
              </v-col>
              <v-col cols="12" class="pt-0">
                {{ item.address.country }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label class="caption grey--text">Contacts</label>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-simple-table hover>
              <thead
                v-if="!loading && item.contacts && item.contacts.length > 0"
              >
                <tr>
                  <th>Name</th>
                  <th>Jobtitle</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contact in item.contacts" :key="contact.id">
                  <td>{{ contact.firstName + ' ' + contact.lastName }}</td>
                  <td>{{ contact.jobTitle }}</td>
                  <td>{{ contact.email }}</td>
                  <td>{{ contact.phone }}</td>
                  <td>
                    <v-row justify="end">
                      <v-btn
                        x-small
                        text
                        rounded
                        color="info"
                        @click="editContact(null, contact)"
                        >edit</v-btn
                      >
                      <v-btn
                        x-small
                        text
                        rounded
                        color="grey"
                        class="ml-2"
                        @click="removeContact(contact.id)"
                        >remove</v-btn
                      >
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-divider
              v-if="item.contacts && item.contacts.length > 0"
            ></v-divider>
            <v-btn text color="info" small block @click="editContact(item.id)"
              >add contact</v-btn
            >
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'WarehouseProviderListTable',
  props: {
    providers: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selected: [],
    search: ''
  }),
  computed: {},
  methods: {
    editItem(item) {
      this.$emit('edit-provider', item)
    },
    removeItem(id) {
      this.$emit('remove-provider', id)
    },
    editContact(providerID, item) {
      this.$emit('edit-contact', providerID, item)
    },
    removeContact(id) {
      this.$emit('remove-contact', id)
    }
  }
}
</script>

<style scoped>
.list {
  padding: 0;
  list-style: none;
  margin-left: -5px;
}

.list-row {
  padding: 0 5px;
  border-radius: 4px;
}

.controls {
  visibility: hidden;
}

.list-row:hover {
  background: #eee;
  cursor: pointer;
}

.list-row:hover .controls {
  visibility: visible;
}
</style>
