<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="light-theme" light>
      <template v-slot:nav-items>
        <!-- <v-list-item
          :to="{ name: 'LogisticsDashboard' }"
          exact
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="activeScope('warehouses')"
          :to="{ name: 'LogisticsWarehouseList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-warehouse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Warehouses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('stock_items')"
          :to="{ name: 'LogisticsStockList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Stock</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'AdvanceShippingNotificationList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-truck-cargo-container</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >Advance Shipping Notifications</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'LogisticsAllocationList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-decision-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Wholesale Allocations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'LogisticsPickTicketList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-ticket-confirmation-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pick Tickets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'LogisticsDeliveryNoteList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Delivery Notes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-file-chart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-subheader class="text-uppercase">Production</v-subheader>

        <v-list-item
          :to="{ name: 'LogisticsSeasonList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Seasons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'LogisticsProductList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('manufacturers')"
          :to="{ name: 'LogisticsManufacturerList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-factory</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manufacturers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('manufacturing_orders')"
          :to="{ name: 'LogisticsManufacturingOrderList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-tray-full</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manufacturing Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader class="text-uppercase">&nbsp;</v-subheader>

        <v-list-item
          v-if="activeScope('logistics_settings')"
          :to="{ name: 'SettingsErrorList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <div class="app-layout pr-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'ProductionLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true
  }),
  apollo: {
    seasons: SEASONS
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userScopes() {
      return this.$store.getters.userScopes
    }
  },
  created() {
    this.$vuetify.theme.themes.light.primary = '#002447'
    this.$vuetify.theme.themes.light.secondary = '#53d3d1'
    this.$vuetify.theme.themes.light.accent = '#fbeceb'
    this.$vuetify.theme.themes.light.error = '#FF5252'
    this.$vuetify.theme.themes.light.info = '#2196F3'
    this.$vuetify.theme.themes.light.success = '#4CAF50'
    this.$vuetify.theme.themes.light.warning = '#FFC107'
  },
  methods: {
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.page-wrap {
  --page-header-height: 64px;
  /* --page-wrap-offset: -20px; */
}
</style>
