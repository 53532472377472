<template>
  <div class="app-layout">
    <div class="page-wrap pr-0">
      <div class="page-header">
        <v-toolbar class="mb-0 b-radius" flat>
          <v-tabs
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            background-color="transparent"
            align-with-title
            center-active
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              :to="{ name: 'SettingsErrorList' }"
              class="text-capitalize"
              exact
              >Errors</v-tab
            >
            <v-tab
              :to="{ name: 'LogisticsSettingsWarehouseProviders' }"
              class="text-capitalize"
              exact
              >Warehouse Providers</v-tab
            >
          </v-tabs>

          <slot name="controls"></slot>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogisticsSettingsLayout'
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 64px;
  /* --page-wrap-offset: -20px; */
}
</style>
