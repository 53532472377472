<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-toolbar v-if="manufacturer" class="mb-0 b-radius" extended flat>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pl-0">
              <v-row>
                <v-col cols="8" lg="9" xl="10">
                  <h2 class="mb-0 text-truncate primary--text">
                    {{ manufacturer.name }}
                  </h2>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <template v-slot:extension>
          <v-tabs
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            background-color="transparent"
            align-with-title
            center-active
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              :to="{
                name: 'LogisticsManufacturerDetail',
                params: { manufacturerID: $route.params.manufacturerID }
              }"
              class="text-capitalize"
              exact
              >Details</v-tab
            >

            <v-tab
              :to="{
                name: 'LogisticsManufacturerLocations',
                params: { manufacturerID: $route.params.manufacturerID }
              }"
              class="text-capitalize"
              >Locations</v-tab
            >

            <v-tab
              class="text-capitalize"
              :to="{
                name: 'LogisticsManufacturerManufacturingOrderList',
                params: { manufacturerID: $route.params.manufacturerID }
              }"
              >Orders</v-tab
            >

            <v-tab
              :to="{
                name: 'LogisticsManufacturerProducts',
                params: { manufacturerID: $route.params.manufacturerID }
              }"
              class="text-capitalize"
              >Products</v-tab
            >

            <v-tab
              :to="{
                name: 'LogisticsManufacturerSettings',
                params: { manufacturerID: $route.params.manufacturerID }
              }"
              class="text-capitalize"
              >Settings</v-tab
            >
          </v-tabs>

          <slot name="controls"> </slot>
        </template>
      </v-toolbar>
    </div>

    <div class="main-page-column py-0">
      <div class="scroll-container pt-0">
        <router-view
          :manufacturer="manufacturer"
          :current-user="currentUser"
          :payment-terms="paymentTerms"
          :currencies="currencies"
          :auth-level="authLevel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MANUFACTURER from '@/graphql/Manufacturer.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'ManufacturerDetailLayout',
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('manufacturers')
    },
    currentRoute() {
      return this.$route
    }
  },
  apollo: {
    manufacturer: {
      query: MANUFACTURER,
      variables() {
        return {
          id: this.$route.params.manufacturerID
        }
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: CURRENCY_LIST
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 112px;
}
</style>
