var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('warehouse-provider-edit-dialog',{attrs:{"dialog":_vm.dialog,"provider":_vm.editedItem,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-provider-edit-dialog":_vm.close,"save-provider-edit-dialog":_vm.saveItem}}),_c('contact-edit-dialog',{attrs:{"dialog":_vm.contactDialog,"contact":_vm.editedContactItem,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-contact-edit-dialog":_vm.close,"save-contact":_vm.saveContact}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.removeProviderDialog),callback:function ($$v) {_vm.removeProviderDialog=$$v},expression:"removeProviderDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"h6 primary--text mb-3"},[_vm._v("Remove Warehouse Provider")]),_c('v-card-text',[_c('p',[_vm._v(" This will remove the provider and can't be undone. ")])]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.removeItem}},[_vm._v("Remove")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.removeContactDialog),callback:function ($$v) {_vm.removeContactDialog=$$v},expression:"removeContactDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"h6 primary--text mb-3"},[_vm._v("Remove Contact")]),_c('v-card-text',[_c('p',[_vm._v(" This will remove the contact and can't be undone. ")])]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.removeContact}},[_vm._v("Remove")])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(
        _vm.warehouseProviders &&
          _vm.warehouseProviders.items &&
          _vm.warehouseProviders.items.length > 0
      )?_c('v-toolbar',{staticClass:"mb-3 b-radius",attrs:{"color":"transparent","flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.newItem}},[_vm._v(" add provider ")])],1)],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"sm":"12","md":"10","lg":"8","xl":"8"}},[(
        _vm.warehouseProviders &&
          _vm.warehouseProviders.items &&
          _vm.warehouseProviders.items.length > 0
      )?_c('div',[_c('warehouse-provider-list-table',{attrs:{"providers":_vm.warehouseProviders.items || [],"loading":_vm.$apollo.loading},on:{"edit-provider":_vm.editItem,"edit-contact":_vm.editContact,"remove-provider":_vm.openRemoveDialog,"remove-contact":_vm.openRemoveContactDialog}})],1):_c('div',[_c('message-box',{attrs:{"title":"Warehouse Providers","caption":"Warehouse Providers are needed to setup warehouses. They can both be internal or an external service partner."}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.newItem}},[_vm._v(" add provider ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }