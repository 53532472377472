<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogisticsDashboard'
}
</script>

<style></style>
