<template>
  <div class="page-wrap">
    <product-detail-header
      :product="seasonProduct"
      :product-assets="productAssets"
      :product-materials="productMaterials"
      :product-manufacturers="productManufacturers"
      :selected-season="selectedSeason"
      :auth-level="authLevel"
      @set-season="setSelectedSeason"
      @change-state="changeState"
    >
      <template v-slot:menu-items>
        <v-tabs
          style="width: 80%;"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'LogisticsProductDetail',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            exact
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="!product || !product.sizes || product.sizes.length === 0"
              >Details</v-badge
            ></v-tab
          >

          <v-tab
            v-if="techPackAuthLevel > 0"
            :to="{
              name: 'LogisticsProductDesignAssets',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
          >
            <v-badge
              color="pink lighten-2"
              dot
              :value="!techPacks || techPacks.length === 0"
            >
              Tech Packs
            </v-badge>
          </v-tab>

          <v-tab
            v-if="styleSpecAuthLevel > 0"
            :to="{
              name: 'LogisticsProductProductionAssets',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
          >
            <v-badge
              color="pink lighten-2"
              dot
              :value="!stylePacks || stylePacks.length === 0"
            >
              Style Specs
            </v-badge></v-tab
          >

          <v-tab
            v-if="manufacturerAuthLevel > 0"
            :to="{
              name: 'LogisticsProductManufacturers',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="
                !productManufacturers || productManufacturers.length === 0
              "
              >Manufacturers</v-badge
            ></v-tab
          >

          <v-tab
            v-if="materialAuthLevel > 0"
            :to="{
              name: 'LogisticsProductMaterials',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="!productMaterials || productMaterials.length === 0"
              >Materials</v-badge
            ></v-tab
          >

          <v-tab
            v-if="logisticsAuthLevel > 0"
            :to="{
              name: 'LogisticsProductCustoms',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="pink lighten-2" dot :value="customCheck"
              >Logistics/Customs</v-badge
            ></v-tab
          >

          <v-tab
            v-if="costingAuthLevel > 0"
            :to="{
              name: 'LogisticsProductCosts',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="info" dot :value="costCheck">Costs</v-badge></v-tab
          >

          <v-tab
            v-if="pricingAuthLevel > 0"
            :to="{
              name: 'LogisticsProductPricing',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="info" dot :value="priceCheck"
              >Pricing</v-badge
            ></v-tab
          >

          <v-tab
            v-if="imageAuthLevel > 0"
            :to="{
              name: 'LogisticsProductImages',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Images</v-tab
          >

          <v-tab
            v-if="inventoryAuthLevel > 0"
            :to="{
              name: 'LogisticsProductInventory',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Inventory</v-tab
          >

          <v-tab
            v-if="seasonAuthLevel > 0"
            :to="{
              name: 'LogisticsProductSeasons',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Seasons</v-tab
          >

          <v-tab
            v-if="authFlowAuthLevel > 0"
            :to="{
              name: 'LogisticsProductAuditFlows',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason }
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Authorisation</v-tab
          >

          <v-tab
            :to="{
              name: 'LogisticsProductChangeLog',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Change Log</v-tab
          >
        </v-tabs>
      </template>
    </product-detail-header>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          v-if="selectedSeason && !this.$apollo.loading"
          :product="seasonProduct"
          :users="users"
          :colors="colors"
          :manufacturer-list="manufacturers"
          :departments="departments"
          :categories="productCategories"
          :article-groups="articleGroups"
          :sub-article-groups="subArticleGroups"
          :style-types="styleTypes"
          :size-groups="sizeGroups"
          :current-user="currentUser"
          :auth-level="authLevel"
          :selected-season="selectedSeason"
          :currency-list="currencyList"
          :seasons="seasons.seasons"
          :price-markups="priceMarkups"
          :warehouses="warehouses"
          @change-state="changeState"
        />
        <div v-if="!selectedSeason && !this.$apollo.loading">
          <message-box
            v-if="product"
            icon="mdi-emoticon-neutral-outline"
            title="No Season Selected"
            caption="Please select a season to continue"
          >
            <v-autocomplete
              v-if="product && product.seasons && product.seasons.length > 0"
              v-model="selectedSeason"
              hide-details
              outlined
              flat
              dense
              background-color="white"
              label="Season"
              placeholder="Select Season"
              item-value="id"
              :filter="seasonFilter"
              color="info"
              item-color="info"
              class="highlight-control info--text mt-3"
              :items="product.seasons"
            >
              <template v-slot:item="{ item }">
                {{ item.longName }} ({{ item.shortName }})
              </template>
              <template v-slot:selection="{ item }">
                <div class="text-truncate">{{ item.shortName }}</div>
              </template>
            </v-autocomplete>
          </message-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import ProductDetailHeader from '@/components/core/ProductDetailHeader.vue'
import PRODUCT from '@/graphql/Product.gql'
import SEASON_PRODUCT from '@/graphql/SeasonProduct.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'
import USERS from '@/graphql/Users.gql'
import COLORS from '@/graphql/Colors.gql'
import SIZE_GROUP_LIST from '@/graphql/SizeGroups.gql'
import CATEGORIES from '@/graphql/ProductCategories.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import SUB_ARTICLE_GROUPS from '@/graphql/SubArticleGroups.gql'
import STYLE_TYPES from '@/graphql/StyleTypes.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import SEASON_LIST from '@/graphql/Seasons.gql'
import PRICE_MARKUP_LIST from '@/graphql/PriceMarkups.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import PRODUCT_ASSETS from '@/graphql/ProductAssets.gql'
import PRODUCT_MANUFACTURER_LIST from '@/graphql/ProductManufacturers.gql'
import PRODUCT_MATERIALS_LIST from '@/graphql/ProductMaterials.gql'

export default {
  name: 'LogisticsProductDetailLayout',
  components: {
    MessageBox,
    ProductDetailHeader
  },
  data: () => ({
    seasons: {
      seasons: []
    }
  }),
  computed: {
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    techPackAuthLevel() {
      return this.$store.getters.authLevel('product_tech_pack')
    },
    styleSpecAuthLevel() {
      return this.$store.getters.authLevel('product_style_spec')
    },
    manufacturerAuthLevel() {
      return this.$store.getters.authLevel('product_manufacturer')
    },
    materialAuthLevel() {
      return this.$store.getters.authLevel('product_material')
    },
    costingAuthLevel() {
      return this.$store.getters.authLevel('product_costing')
    },
    pricingAuthLevel() {
      return this.$store.getters.authLevel('product_pricing')
    },
    imageAuthLevel() {
      return this.$store.getters.authLevel('product_image')
    },
    inventoryAuthLevel() {
      return this.$store.getters.authLevel('product_inventory')
    },
    seasonAuthLevel() {
      return this.$store.getters.authLevel('product_season')
    },
    authFlowAuthLevel() {
      return this.$store.getters.authLevel('product_audit_flow')
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    currentApprover() {
      var that = this
      if (
        that.product &&
        (!that.product.auditFlows || that.product.auditFlows.length === 0)
      ) {
        return null
      } else {
        if (
          that.product &&
          that.product.productState === 'REVIEW' &&
          that.currentUser
        ) {
          var approver = null

          that._.find(that.product.auditFlows, function(item) {
            if (item.status === 'review') {
              approver = that._.find(item.auditors, function(auditor) {
                return auditor.status === 'review'
              })
            }
          })

          if (
            approver &&
            approver.userID === that.currentUser.id &&
            that.currentUser
          ) {
            return approver
          }
        }
        return null
      }
    },
    stylePacks() {
      return this._.find(this.productAssets, function(item) {
        return item.assetType === 'STYLEPACK'
      })
    },
    styleCheck() {
      return (
        !this.product ||
        !this.product.sizes ||
        this.product.sizes.length === 0 ||
        !this.techPacks ||
        this.techPacks.length === 0 ||
        !this.stylePacks ||
        this.stylePacks.length === 0 ||
        !this.productMaterials ||
        this.productMaterials.length === 0
      )
    },
    techPacks() {
      return this._.find(this.productAssets, function(item) {
        return item.assetType === 'TECHPACK'
      })
    },
    customCheck() {
      var check =
        this.product &&
        this.product.customType &&
        (this.product.htsCode || this.product.intrastatCode)
          ? false
          : true

      return check
    },
    costCheck() {
      var that = this

      var items = []

      this._.each(this.productManufacturers, function(item) {
        var cost = that._.find(item.costs, function(child) {
          return child.seasonID === that.selectedSeason
        })

        if (cost) items.push(cost)
      })

      return (
        items === 0 ||
        !that.productManufacturers ||
        that.productManufacturers.length === 0 ||
        items.length === 0 ||
        (items.length > 0 && items.length !== that.productManufacturers.length)
      )

      // return items
    },
    priceCheck() {
      var that = this

      var items = []

      if (
        that.seasonProduct &&
        that.seasonProduct.prices &&
        that.seasonProduct.prices.length > 0
      ) {
        items = that._.filter(this.seasonProduct.prices, function(item) {
          return item.seasonID === that.selectedSeason
        })
      }

      return (
        !that.seasonProduct ||
        !that.seasonProduct.prices ||
        that.seasonProduct.prices.length === 0 ||
        items.length === 0 ||
        (items.length > 0 && items.length !== that.seasonProduct.prices.length)
      )
    },
    currencyList() {
      return this.currencies
    },
    authLevel() {
      return this.$store.getters.authLevel('products')
    },
    currentRoute() {
      return this.$route
    },
    selectedSeason: {
      get() {
        return this.$route.query.season
      },
      set(id) {
        this.setSelectedSeason(id)
      }
    }
  },
  apollo: {
    seasonProduct: {
      query: SEASON_PRODUCT,
      variables() {
        return {
          id: this.$route.params.productID,
          seasonID: this.selectedSeason
        }
      },
      fetchPolicy: 'no-cache',
      skip() {
        return !this.selectedSeason
      }
      // result({ data }) {
      //   // this.$nextTick(() => {
      //   this.setRouterProductName(data.product.name)
      //   // })
      // }
    },
    product: {
      query: PRODUCT,
      variables() {
        return {
          id: this.$route.params.productID
        }
      }
    },
    productAssets: {
      query: PRODUCT_ASSETS,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    productManufacturers: {
      query: PRODUCT_MANUFACTURER_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    productMaterials: {
      query: PRODUCT_MATERIALS_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    departments: DEPARTMENTS,
    users: USERS,
    colors: COLORS,
    productCategories: CATEGORIES,
    articleGroups: ARTICLE_GROUPS,
    subArticleGroups: SUB_ARTICLE_GROUPS,
    styleTypes: STYLE_TYPES,
    sizeGroups: SIZE_GROUP_LIST,
    manufacturers: MANUFACTURER_LIST,
    currencies: CURRENCY_LIST,
    seasons: SEASON_LIST,
    priceMarkups: PRICE_MARKUP_LIST,
    warehouses: WAREHOUSE_LIST
  },
  watch: {},
  created() {},
  methods: {
    setSelectedSeason(seasonID) {
      this.$router.push({
        name: this.currentRoute.name,
        params: { productID: this.currentRoute.params.productID },
        query: { season: seasonID }
      })
      // this.$apollo.queries.seasonProduct.refetch()
    },
    setRouterProductName(name) {
      if (name) {
        let resolved = this.$route.matched
        let detailIdx = resolved.length - 2

        // this.$nextTick(() => {
        this.$route.matched[detailIdx].meta.breadCrumb = name

        // })
      }
    },
    seasonFilter(item, queryText) {
      const longName = item.longName.toLowerCase()
      const shortName = item.shortName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        longName.indexOf(searchText) > -1 || shortName.indexOf(searchText) > -1
      )
    },
    changeState() {
      this.$apollo.queries.seasonProduct.refetch()
      this.$apollo.queries.productManufacturers.refetch()
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
