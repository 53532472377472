var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[(_vm.season)?_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"extended":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsSeasonDetail',
              params: { seasonID: _vm.$route.params.seasonID }
            },"exact":""}},[_vm._v("Details")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsSeasonAssets',
              params: { seasonID: _vm.$route.params.seasonID }
            }}},[_vm._v(" Collection Overview ")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsSeasonProducts',
              params: { seasonID: _vm.$route.params.seasonID }
            },"exact":""}},[_vm._v("Products")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LogisticsSeasonSettings',
              params: { seasonID: _vm.$route.params.seasonID }
            },"exact":""}},[_vm._v("Settings")])],1),_vm._t("controls")]},proxy:true}],null,true)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"start","align":"start"}},[_c('div',{attrs:{"sm6":""}},[_c('h2',{staticClass:"mb-0 primary--text"},[_vm._v(" "+_vm._s(_vm.season.longName)+" ("+_vm._s(_vm.season.shortName)+") ")]),_c('div',[(
                      _vm.season.from && _vm.season.till
                        ? _vm.isCurrentSeason(_vm.season.from, _vm.season.till)
                        : false
                    )?_c('v-chip',{attrs:{"x-small":"","color":"success lighten-3 font-weight-medium upperCaseSpacing","text-color":"success"}},[_vm._v("active")]):_vm._e()],1)]),_c('v-spacer'),_c('div',{attrs:{"sm6":""}})],1)],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"main-page-column"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('router-view',{attrs:{"season":_vm.season,"current-user":_vm.currentUser,"auth-level":_vm.authLevel}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }