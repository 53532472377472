<template>
  <div class="page-wrap">
    <div class="page-header">
      <!-- Toolbar -->
      <v-toolbar v-if="warehouse" class="mb-0 b-radius" extended flat>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pl-0">
              <v-row>
                <v-col cols="8" lg="9" xl="10">
                  <h2 class="mb-0 text-truncate primary--text">
                    {{ warehouse.name }}
                  </h2>
                  <div v-if="warehouse.provider" class="text-body2">
                    {{ warehouse.provider.name }}
                    <!-- <v-chip
                      v-if="warehouse && warehouse.provider"
                      pill
                      x-small
                      color="info lighten-2"
                      text-color="info darken-2"
                      >{{ warehouse.provider.name }}</v-chip
                    > -->
                  </div>
                </v-col>
                <v-spacer></v-spacer>

                <v-col cols="4" lg="3" xl="2"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <template v-slot:extension>
          <v-tabs
            style="width: 80%;"
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            background-color="transparent"
            align-with-title
            center-active
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              :to="{
                name: 'LogisticsWarehouseDetail',
                params: { warehouseID: $route.params.warehouseID }
              }"
              class="text-capitalize"
              exact
              >Details</v-tab
            >

            <v-tab
              :to="{
                name: 'LogisticsWarehouseStockList',
                params: { warehouseID: $route.params.warehouseID }
              }"
              class="text-capitalize"
              >Stock</v-tab
            >

            <v-tab
              :to="{
                name: 'LogisticsWarehouseSettings',
                params: { warehouseID: $route.params.warehouseID }
              }"
              class="text-capitalize"
              >Settings</v-tab
            >
          </v-tabs>

          <v-spacer v-if="warehouse && authLevel && authLevel === 2"></v-spacer>

          <slot name="controls"></slot>
        </template>
      </v-toolbar>
    </div>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          :warehouse-providers="warehouseProviders"
          :warehouse="warehouse"
          :current-user="currentUser"
          :auth-level="authLevel"
          :loading="$apollo.loading"
          @refresh-warehouse="refreshWarehouse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WAREHOUSE from '@/graphql/Warehouse.gql'
import WAREHOUSE_PROVIDER_LIST from '@/graphql/WarehouseProviders.gql'

export default {
  name: 'WarehouseDetailLayout',
  components: {},
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('warehouses')
    }
  },
  apollo: {
    warehouse: {
      query: WAREHOUSE,
      variables() {
        return {
          id: this.$route.params.warehouseID
        }
      }
    },
    warehouseProviders: {
      query: WAREHOUSE_PROVIDER_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  watch: {},
  created() {},
  methods: {
    refreshWarehouse() {
      this.$apollo.queries.warehouse.refetch()
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
