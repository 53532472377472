<template>
  <v-row justify="center">
    <warehouse-provider-edit-dialog
      :dialog="dialog"
      :provider="editedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-provider-edit-dialog="close"
      @save-provider-edit-dialog="saveItem"
    ></warehouse-provider-edit-dialog>
    <contact-edit-dialog
      :dialog="contactDialog"
      :contact="editedContactItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-contact-edit-dialog="close"
      @save-contact="saveContact"
    ></contact-edit-dialog>
    <v-dialog v-model="removeProviderDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="h6 primary--text mb-3"
          >Remove Warehouse Provider</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the provider and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeItem">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeContactDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="h6 primary--text mb-3"
          >Remove Contact</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the contact and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeContact">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" class="py-0">
      <v-toolbar
        v-if="
          warehouseProviders &&
            warehouseProviders.items &&
            warehouseProviders.items.length > 0
        "
        color="transparent"
        flat
        class="mb-3 b-radius"
      >
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-btn color="primary" rounded @click="newItem">
                add provider
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>
    <v-col sm="12" md="10" lg="8" xl="8">
      <div
        v-if="
          warehouseProviders &&
            warehouseProviders.items &&
            warehouseProviders.items.length > 0
        "
      >
        <warehouse-provider-list-table
          :providers="warehouseProviders.items || []"
          :loading="$apollo.loading"
          @edit-provider="editItem"
          @edit-contact="editContact"
          @remove-provider="openRemoveDialog"
          @remove-contact="openRemoveContactDialog"
        ></warehouse-provider-list-table>
      </div>
      <div v-else>
        <message-box
          title="Warehouse Providers"
          caption="Warehouse Providers are needed to setup warehouses. They can both be internal or an external service partner."
        >
          <v-btn color="primary" rounded @click="newItem">
            add provider
          </v-btn>
        </message-box>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import WAREHOUSE_PROVIDER_LIST from '@/graphql/WarehouseProviders.gql'
import WAREHOUSE_PROVIDER_CREATE from '@/graphql/WarehouseProviderCreate.gql'
import WAREHOUSE_PROVIDER_UPDATE from '@/graphql/WarehouseProviderUpdate.gql'
import WAREHOUSE_PROVIDER_REMOVE from '@/graphql/WarehouseProviderRemove.gql'
import CONTACT_CREATE from '@/graphql/WarehouseProviderContactCreate.gql'
import CONTACT_UPDATE from '@/graphql/WarehouseProviderContactUpdate.gql'
import CONTACT_REMOVE from '@/graphql/WarehouseProviderContactRemove.gql'
import WarehouseProviderListTable from '../components/core/WarehouseProviderListTable.vue'
import WarehouseProviderEditDialog from '../components/core/WarehouseProviderEditDialog.vue'
import ContactEditDialog from '../components/core/ContactEditDialog.vue'

export default {
  name: 'LogisticsSettingsWarehouseProviders',
  components: {
    MessageBox,
    WarehouseProviderListTable,
    WarehouseProviderEditDialog,
    ContactEditDialog
  },
  data: () => ({
    filters: false,
    search: null,
    options: { page: 1, itemsPerPage: 100 },
    itemCount: 0,
    dialog: false,
    removeProviderDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    selected: [],
    warehouseProviders: { items: [] },
    defaultItem: {
      id: null,
      name: null,
      website: null,
      // providerKey: null,
      // liveEndpoint: null,
      // testEndpoint: null,
      // devEndpoint: null,
      address: {
        id: null,
        line1: null,
        line2: null,
        line3: null,
        postCode: null,
        city: null,
        country: null,
        email: null,
        phone: null
      }
    },
    editedItem: {},
    deletedID: null,

    contactDialog: false,
    removeContactDialog: false,
    defaultContactItem: {
      id: null,
      firstName: null,
      lastName: null,
      jobTitle: null,
      email: null,
      phone: null
    },
    editedContactItem: {}
  }),
  computed: {},
  apollo: {
    warehouseProviders: {
      query: WAREHOUSE_PROVIDER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.warehouseProviders.page
        this.itemsPerPage = result.data.warehouseProviders.pageSize
        this.itemCount = result.data.warehouseProviders.total
      }
    }
  },
  methods: {
    close() {
      this.editMode = false
      this.editedItem = {}
      this.editedContactItem = {}
      this.dialog = false
      this.contactDialog = false
      this.removeContactDialog = false
      this.removeProviderDialog = false
      this.processing = false
    },
    newItem() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    editItem(item) {
      this.editMode = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    openRemoveDialog(id) {
      this.deletedID = id
      this.removeProviderDialog = true
    },
    removeItem() {
      this.$apollo
        .mutate({
          mutation: WAREHOUSE_PROVIDER_REMOVE,
          variables: {
            id: this.deletedID
          }
        })
        .then(() => {
          this.$apollo.queries.warehouseProviders.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Provider Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again'
          })
          this.processing = false
        })
    },
    saveItem(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: WAREHOUSE_PROVIDER_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.warehouseProviders.refetch()
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Provider Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: WAREHOUSE_PROVIDER_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.warehouseProviders.refetch()
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Provider Added'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },

    editContact(providerID, item) {
      if (item && item.id) {
        // Edit existing
        this.editMode = true
        this.editedContactItem = this._.cloneDeep(item)
      } else {
        // Create new
        this.editedContactItem = this._.cloneDeep(this.defaultContactItem)
        this.editedContactItem.warehouseProviderID = providerID
      }

      this.contactDialog = true
    },
    saveContact(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: CONTACT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.warehouseProviders.refetch()
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: CONTACT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.warehouseProviders.refetch()
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Added'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    openRemoveContactDialog(id) {
      this.deletedID = id
      this.removeContactDialog = true
    },
    removeContact() {
      this.$apollo
        .mutate({
          mutation: CONTACT_REMOVE,
          variables: {
            id: this.deletedID
          }
        })
        .then(() => {
          this.$apollo.queries.warehouseProviders.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Contact Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again'
          })
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
