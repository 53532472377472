<template>
  <div class="page-wrap">
    <div class="page-header">
      <product-list-toolbar
        :auth-level="authLevel"
        :seasons="seasons.seasons"
        :loading="$apollo"
        :designers="designers"
        :product-developers="productDevelopers"
        :products="products"
        :users="users"
        :item-count="itemCount"
        @set-filters="setFilters"
        @set-selected-designer="setSelectedDesigner"
        @set-selected-producer="setSelectedProducer"
      ></product-list-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-card v-if="filters" class="mb-3">
          <div>
            <v-col cols="12">
              <v-row no-gutters>
                <span>Advanced Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="filters = !filters"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>

            <v-col cols="12">
              <season-switcher
                :background-color="'#fff'"
                :items="seasons.seasons"
                :prepend-inner-icon="'mdi-filter-variant'"
                :disabled="
                  $apollo.queries.seasons.loading ||
                    !seasons ||
                    seasons.seasons.length === 0
                "
              ></season-switcher>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="selectedDesigner"
                :items="designers"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="designer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedProducer"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :items="productDevelopers"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
          </div>
        </v-card>

        <product-list-table
          :loading="$apollo.loading"
          :products="products"
          :server-items-length="itemCount"
          @search="searchItems"
          @page-change="changePage"
          @nav-to-detail="navToDetail"
        ></product-list-table>
      </div>
    </div>
  </div>
</template>

<script>
import SeasonSwitcher from '@/components/core/SeasonSwitcher.vue'
import ProductListToolbar from '@/components/core/ProductListToolbar'
import ProductListTable from '@/components/core/ProductListTable'
import SEASONS from '@/graphql/Seasons.gql'
import PRODUCTS from '@/graphql/Products.gql'
// import CREATE_PRODUCT from '@/graphql/ProductCreate.gql'
import USERS from '@/graphql/Users.gql'
import COLORS from '@/graphql/Colors.gql'
import CATEGORIES from '@/graphql/ProductCategories.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import STYLE_TYPES from '@/graphql/StyleTypes.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'LogisticsProductList',
  components: { ProductListToolbar, ProductListTable, SeasonSwitcher },
  props: {},
  data: () => ({
    loaded: false,
    filters: false,
    selectedDesigner: null,
    selectedProducer: null,
    search: null,
    page: 1,
    itemsPerPage: 15,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    editDialog: false,
    editMode: false,
    errorMsg: '',
    selected: [],
    products: { items: [] },
    users: [],
    seasons: { seasons: [] },
    processing: false,
    defaultItem: {
      name: '',
      styleNumber: '',
      description: '',
      gender: 'MALE',
      designer: {}
    },
    editedItem: {
      name: '',
      styleNumber: '',
      description: '',
      gender: 'MALE',
      designer: {}
    }
  }),
  apollo: {
    users: USERS,
    seasons: SEASONS,
    colors: COLORS,
    productCategories: CATEGORIES,
    articleGroups: ARTICLE_GROUPS,
    styleTypes: STYLE_TYPES,
    departments: DEPARTMENTS,
    products: {
      query: PRODUCTS,
      variables() {
        return {
          filters: {
            nameSearch: this.search,
            season: this.selectedSeason,
            designer: this.selectedDesigner,
            producer: this.selectedProducer,
            page: this.page,
            pageSize: this.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.products.page
        this.itemsPerPage = result.data.products.pageSize
        this.itemCount = result.data.products.total

        // Set page state
        this.$store.commit('production/SET_PAGE', this.page)
        this.$store.commit('production/SET_PAGE_SIZE', this.itemsPerPage)
        this.$store.commit('production/SET_SEASON', this.selectedSeason)
        this.$store.commit('production/SET_DESIGNER', this.selectedDesigner)
        this.$store.commit('production/SET_PRODUCER', this.selectedProducer)
        this.$store.commit('production/SET_SEARCH', this.search)
      }
    }
  },
  computed: {
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    productDeailAccess() {
      if (
        this.salesAuthLevel > 0 ||
        this.designAuthLevel > 0 ||
        this.developmentAuthLevel > 0 ||
        this.logisticsAuthLevel > 0
      ) {
        return true
      } else {
        return false
      }
    },
    headers() {
      return [
        // {
        //   text: '',
        //   align: 'left',
        //   sortable: false,
        //   filterable: true,
        //   value: 'images.thumbnail.url'
        // },
        {
          text: 'Style Status',
          align: 'left',
          value: 'styleState',
          sortable: false,
          filter: this.stateFilterValue
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Style Number',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'styleNumber'
        },
        {
          text: 'Color',
          value: 'seasonColor',
          sortable: false,
          filterable: true
        },
        {
          text: 'Composition',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'textileContent'
        },
        {
          text: 'Gender',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          filterable: true
        }
        // {
        //   text: 'Designer',
        //   value: 'designers',
        //   sortable: false,
        //   filterable: true
        // }
      ]
    },
    selectedSeason() {
      return this.$store.state.selectedSeason
        ? this.$store.state.selectedSeason.id
        : null
    },
    authLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    designDepartment() {
      return this._.find(this.departments, item => item.name === 'Design')
    },
    productionDepartment() {
      return this._.find(this.departments, item => item.name === 'Production')
    },
    designers() {
      var vm = this

      if (this.designDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.designDepartment.id
        )
      } else {
        return []
      }
    },
    productDevelopers() {
      var vm = this
      if (this.productionDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.productionDepartment.id
        )
      } else {
        return []
      }
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.page = 1
    }
  },
  methods: {
    setPage() {
      var that = this
      var filters = this.$store.state.production.productsPage
      this.search = filters.search
      // Apply state filters
      that.page = filters.page
      that.itemsPerPage = filters.pageSize
      // that.selectedSeason = filters.season
      that.selectedDesigner = filters.designer
      that.selectedProducer = filters.producer
    },
    changePage(val) {
      this.page = val.page
      this.pageSize = val.pageSize
      this.options = val
    },
    setFilters(val) {
      this.filters = val
    },
    setSelectedDesigner(item) {
      this.selectedDesigner = item
    },
    setSelectedProducer(item) {
      this.selectedProducer = item
    },
    searchItems(val) {
      var that = this
      var filters = this.$store.state.production.productsPage
      this.search = val
      // Apply state filters
      that.page = filters.page
      that.itemsPerPage = filters.pageSize
      // that.selectedSeason = filters.season
      that.selectedDesigner = filters.designer
      that.selectedProducer = filters.producer
    },
    navToDetail(item) {
      // if (this.productDeailAccess) {
      var seasonID = this.selectedSeason || item.seasons[0].id

      this.$router.push({
        name: 'LogisticsProductDetail',
        params: { productID: item.id },
        query: { season: seasonID }
      })
    }
  }
}
</script>

<style></style>
