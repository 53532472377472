<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="providerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                      autofocus
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.providerKey"
                      label="Provider Key (optional)"
                      hint="This should be provided by the warehouse provider"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.website"
                      label="Website"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.address.email"
                      label="Email"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                      type="email"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.address.phone"
                      label="Phone"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.address.line1"
                      label="Address Line1"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="item.address.line2"
                      label="Address Line2"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.address.postCode"
                      label="Postcode"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="item.address.city"
                      label="City"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.address.country"
                      :items="countryList"
                      label="Country"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.devEndpoint"
                      label="Dev Endpoint"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.testEndpoint"
                      label="Test Endpoint"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.liveEndpoint"
                      label="Live Endpoint"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import country from 'country-list'
import { countries } from 'country-data-list'

export default {
  name: 'WarehouseProviderEditDialog',
  props: {
    provider: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode
        ? 'Edit Warehouse Provider'
        : 'New Warehouse Provider'
    },
    countryList() {
      return this._.sortBy(countries.all, 'name')
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.provider)
    }
  },
  methods: {
    close() {
      // this.$refs.providerForm.reset()
      this.$emit('close-provider-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.providerForm.resetValidation()
      })
    },
    save() {
      var that = this
      if (this.$refs.providerForm.validate()) {
        var data = {
          id: this.item.id,
          name: this.item.name,
          providerKey: this.item.providerKey,
          website: this.item.website,
          address: {
            id: this.item?.address?.id,
            email: this.item?.address?.email,
            phone: this.item?.address?.phone,
            line1: this.item?.address?.line1,
            line2: this.item?.address?.line2,
            line3: this.item?.address?.line3,
            city: this.item?.address?.city,
            postCode: this.item?.address?.postCode,
            country: this.item?.address?.country,
            addressType: this.item?.address?.addressType || 'VISITING'
          }
        }

        if (!this.editMode) {
          delete data.id
          delete data.address.id
        } else {
          delete data.address.addressType
        }

        this.$emit('save-provider-edit-dialog', data)
        this.$nextTick(() => {
          that.$refs.providerForm.resetValidation()
        })
      }
    }
  }
}
</script>

<style></style>
