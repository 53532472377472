<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div v-if="item">
        <div class="scroll-container pt-0">
          <v-form ref="asnForm" v-model="valid" lazy-validation @submit.prevent>
            <v-row id="scroll-target" justify="center">
              <v-col v-if="!editMode" cols="12">
                <v-checkbox
                  v-model="item.batchASN"
                  dense
                  label="Batch"
                  hint="Batch multiple asns into one shipment"
                  persistent-hint
                  :disabled="editMode"
                ></v-checkbox>
              </v-col>
              <v-col v-if="!item.batchASN" cols="12">
                <v-autocomplete
                  v-if="!editMode"
                  v-model="item.manufacturingOrder"
                  :items="manufacturingOrders"
                  :search-input.sync="search"
                  autocomplete="off"
                  label="Manufacturing Order"
                  hide-details="auto"
                  outlined
                  item-text="id"
                  :rules="requiredSelectRule"
                  clearable
                  dense
                  return-object
                >
                  <template v-slot:selection="data">
                    <span class="font-weight-medium"
                      >{{ data.item.id }} &nbsp;</span
                    >
                    <span v-if="data.item.docketRef !== ''">
                      - {{ data.item.docketRef }}</span
                    >
                  </template>
                  <template v-slot:item="data">
                    <span class="font-weight-medium"
                      >{{ data.item.id }} &nbsp;</span
                    >
                    <span v-if="data.item.docketRef !== ''">
                      - {{ data.item.docketRef }}</span
                    >
                  </template>
                </v-autocomplete>
                <div v-else>
                  <div class="text-caption mb-1 grey--text">
                    Manufacturing Order
                  </div>
                  <span class="font-weight-medium"
                    >{{ item.manufacturingOrder.id }} &nbsp;</span
                  >
                  <span v-if="item.manufacturingOrder.docketRef !== ''">
                    - {{ item.manufacturingOrder.docketRef }}</span
                  >
                </div>
              </v-col>
              <v-col v-if="!editMode && item.batchASN" cols="12">
                <v-autocomplete
                  v-model="item.manufacturer"
                  :items="manufacturers"
                  :search-input.sync="searchManufacturer"
                  autocomplete="off"
                  label="Manufacturer"
                  hide-details="auto"
                  outlined
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  return-object
                  :rules="requiredRule"
                  :disabled="editMode"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="!editMode && item.batchASN" cols="12">
                <v-autocomplete
                  v-model="item.warehouse"
                  :items="warehouseList"
                  autocomplete="off"
                  label="Warehouse"
                  hide-details="auto"
                  outlined
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  return-object
                  :rules="requiredRule"
                  :disabled="editMode"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="!item.batchASN" cols="12">
                <v-autocomplete
                  v-model="item.parent"
                  :items="asns"
                  :search-input.sync="searchASN"
                  autocomplete="off"
                  label="Batch Parent ASN"
                  hint="Add to a batch asn for processing of multiple ASNS in one shipment"
                  persistent-hint
                  hide-details="auto"
                  outlined
                  item-text="asnID"
                  clearable
                  dense
                  return-object
                  :disabled="editMode && item.state !== 'DRAFT'"
                >
                  <template v-slot:selection="data">
                    <span class="font-weight-medium"
                      >{{ data.item.asnID }} &nbsp;</span
                    >
                    <span v-if="data.item.manufacturerName !== ''">
                      - {{ data.item.manufacturerName }}</span
                    >
                  </template>
                  <template v-slot:item="data">
                    <span class="font-weight-medium"
                      >{{ data.item.asnID }} &nbsp;</span
                    >
                    <span v-if="data.item.manufacturerName !== ''">
                      - {{ data.item.manufacturerName }}</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu1"
                      v-model="shippingDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedShippingDate"
                          autocomplete="off"
                          outlined
                          label="Shipping Date"
                          placeholder="Shipping Date"
                          prepend-inner-icon="mdi-calendar-month-outline"
                          dense
                          hide-details="auto"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.shippingDate"
                        no-title
                        hide-details
                        first-day-of-week="1"
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu2"
                      v-model="deliveryDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedDeliveryDate"
                          autocomplete="off"
                          outlined
                          label="Delivery Date"
                          placeholder="Delivery Date"
                          prepend-inner-icon="mdi-calendar-month-outline"
                          dense
                          hide-details="auto"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.deliveryDate"
                        no-title
                        hide-details
                        first-day-of-week="1"
                        :min="
                          $moment(item.shippingDate) > $moment()
                            ? $moment(item.shippingDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            : $moment()
                                .add(2, 'days')
                                .format('YYYY-MM-DD')
                        "
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.notes"
                  label="Notes"
                  placeholder="Any notes you want to add..."
                  hide-details="auto"
                  autocomplete="off"
                  dense
                  outlined
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AdvanceShippingNotificationEditDialog',
  props: {
    asn: {
      type: Object,
      required: true,
      default: () => ({})
    },
    asns: {
      type: Array,
      required: true,
      default: () => []
    },
    manufacturingOrders: {
      type: Array,
      required: true,
      default: () => []
    },
    manufacturers: {
      type: Array,
      required: true,
      default: () => []
    },
    warehouses: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    search: null,
    searchASN: null,
    searchManufacturer: null,
    item: {},
    shippingDate: null,
    deliveryDate: null
  }),
  computed: {
    warehouseList() {
      return this._.filter(this.warehouses, {type: 'WHOLESALE'})
    },
    title() {
      return this.editMode ? 'Edit ASN' : 'New ASN'
    },
    formattedShippingDate() {
      return this.item.shippingDate
        ? this.$moment(this.item.shippingDate).format('LL')
        : ''
    },
    formattedDeliveryDate() {
      return this.item.deliveryDate
        ? this.$moment(this.item.deliveryDate).format('LL')
        : ''
    }
  },
  watch: {
    editMode(val) {
      if (val && this.asn.parentID && this.asn.parentID !== '') {
        this.searchASN = this.asn.parentID
      }
    },
    dialog(val) {
      if (val) {
        this.item = this._.cloneDeep(this.asn)

        if (!this.item.shippingDate)
          this.item.shippingDate = this.$moment().format('YYYY-MM-DD')

        if (!this.item.deliveryDate)
          this.item.deliveryDate = this.$moment()
            .add(2, 'days')
            .format('YYYY-MM-DD')
      }
    },
    search(val) {
      if (val) {
        this.$emit('search-manufacturing-order', val)
      } else if (this.item.manufacturingOrder) {
        this.$emit(
          'search-manufacturing-order',
          this.item.manufacturingOrder.id
        )
      } else {
        this.$emit('search-manufacturing-order', null)
      }
    },
    searchASN(val) {
      if (val) {
        this.$emit('search-asn', val)
      } else if (this.item.parent?.asnID) {
        this.$emit('search-asn', this.item.parent.asnID)
      } else {
        this.$emit('search-asn', null)
      }
    },
    searchManufacturer(val) {
      if (val && val !== '') {
        this.$emit('search-manufacturers', val)
      } else if (this.item.manufacturer?.id) {
        this.$emit('search-manufacturers', this.item.manufacturer.name)
      } else {
        this.$emit('search-manufacturers', null)
      }
    },
    formattedShippingDate(val) {
      this.$moment(val) >= this.$moment(this.item.deliveryDate)
        ? (this.item.deliveryDate = this.$moment(this.item.shippingDate)
            .add(2, 'days')
            .format('YYYY-MM-DD'))
        : null
    }
  },
  methods: {
    close() {
      this.$emit('close-asn-edit-dialog')

      this.$nextTick(() => {
        this.$refs.asnForm.resetValidation()
      })
    },
    save() {
      var that = this
      if (this.$refs.asnForm.validate()) {
        var data = {
          id: this.item.id,
          shippingDate: this.item.shippingDate
            ? this.$moment(this.item.shippingDate).utc()
            : null,
          deliveryDate: this.item.deliveryDate
            ? this.$moment(this.item.deliveryDate).utc()
            : null,
          notes: this.item.notes,
          position: this.item.position,
          batchASN: this.item.batchASN
        }

        if (!this.item.batchASN) {
          data.manufacturingOrderID = this.item.manufacturingOrder?.id
          data.parentID = this.item.parent?.id
        } else {
          data.manufacturingOrderID = null
          data.manufacturerID = this.item.manufacturer?.id
          data.warehouseID = this.item.warehouse?.id
          data.parentID = null
        }

        if (!this.editMode) {
          delete data.id
        } else {
          delete data.manufacturingOrderID
          delete data.manufacturerID
          delete data.warehouseID
        }

        this.$emit('save-asn-edit-dialog', data)
        this.$nextTick(() => {
          that.$refs.asnForm.resetValidation()
        })
      }
    }
  }
}
</script>

<style></style>
