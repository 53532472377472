<template>
  <div class="app-layout">
    <div class="page-wrap pr-4">
      <div class="page-header">
        <advance-shipping-notification-edit-dialog
          :dialog="dialog"
          :asn="editedItem"
          :asns="batchAdvanceShippingNotifications.items"
          :manufacturers="manufacturers.items"
          :manufacturing-orders="manufacturingOrders.items"
          :warehouses="warehouses.items"
          :edit-mode="editMode"
          :processing="processing"
          :error-msg="errorMsg"
          @search-manufacturing-order="searchManufacturingOrder"
          @search-asn="searchAdvanceShippingNotification"
          @search-manufacturers="searchManufacturers"
          @close-asn-edit-dialog="close"
          @save-asn-edit-dialog="save"
        ></advance-shipping-notification-edit-dialog>
        <v-toolbar class="mb-0 b-radius" flat>
          <v-row>
            <v-col cols="4" lg="2">
              <v-btn class="ml-2" color="primary" rounded @click="addASN">
                add asn
              </v-btn>
            </v-col>
            <v-col cols="4" lg="3" xl="3">
              <v-select
                v-model="asnState"
                :items="asnStates"
                hide-details
                outlined
                flat
                dense
                clearable
                label="Status"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
        </v-toolbar>
      </div>
      <div class="main-page-column pr-0 pt-0">
        <div class="scroll-container pt-0">
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                hide-details
                flat
                clearable
                background-color="#fff"
                placeholder="Search by ASN No..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="advanceShippingNotifications.items"
              :options.sync="options"
              :loading="$apollo.queries.advanceShippingNotifications.loading"
              :mobile-breakpoint="null"
              :server-items-length="itemCount"
              class="plain-table grey--text text--darken-2"
              :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
              :hide-default-footer="itemCount < options.itemsPerPage + 1"
              :hide-default-header="
                advanceShippingNotifications.itemCount === 0
              "
              @click:row="navToDetail"
            >
              <template v-slot:item.warehouse="{ item }">
                <div class="text-truncate">{{ item.warehouse.name }}</div>
                <div class="text-truncate grey--text text-caption">
                  {{ item.warehouse.provider.name }}
                </div>
              </template>
              <template v-slot:item.manufacturerName="data">
                <div class="text-truncate">
                  {{ data.item.manufacturerName }}
                </div>
              </template>
              <template v-slot:item.shippingDate="{ item }">
                <span class="text-truncate"
                  >{{ item.shippingDate | moment('DD MMM YY') }}
                </span>
              </template>
              <template v-slot:item.deliveryDate="{ item }">
                <span class="text-truncate"
                  >{{ item.deliveryDate | moment('DD MMM YY') }}
                </span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span class="text-truncate">{{
                  item.createdAt | moment('DD MMM YY')
                }}</span>
              </template>
              <template v-slot:item.manufacturingOrderID="{ item }">
                <span
                  v-if="item.manufacturingOrderID && !item.batchASN"
                  class="text-truncate"
                  >{{ item.manufacturingOrderID }}</span
                >
              </template>
              <template v-slot:item.state="{ item }">
                <div>
                  <v-chip v-if="item.state === 'DRAFT'" x-small>{{
                    item.state
                  }}</v-chip>
                  <v-chip
                    v-if="item.state === 'SHIPPING'"
                    x-small
                    color="success lighten-3 font-weight-medium"
                    text-color="success"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'ALLOCATING'"
                    x-small
                    color="info lighten-2 font-weight-medium"
                    text-color="info darken-1"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'CLOSED'"
                    x-small
                    color="warning lighten-1 font-weight-medium"
                    text-color="warning darken-3"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.parentID"
                    x-small
                    color="teal lighten-4 font-weight-medium"
                    text-color="teal darken-3"
                    class="ml-3"
                    >Child</v-chip
                  >
                  <v-chip
                  v-if="item.batchASN"
                  x-small
                  color="info lighten-2 font-weight-medium"
                  text-color="info darken-3"
                  class="ml-3"
                  >BATCH</v-chip
                >
                </div>
              </template>
              <template v-slot:item.controls="{ item }">
                <v-btn
                  v-if="item.parentID"
                  :to.prevent="{
                    name: 'AdvanceShippingNotificationDetails',
                    params: { asnID: item.id }
                  }"
                  class="ml-3"
                  x-small
                  rounded
                  text
                  color="info"
                  >view batch asn</v-btn
                >
              </template>
              <template v-slot:no-data>
                <message-box
                  icon="mdi-truck-cargo-container"
                  title="No Advance Shipping Notifications"
                >
                </message-box>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import AdvanceShippingNotificationEditDialog from '@/modules/logistics/components/core/AdvanceShippingNotificationEditDialog.vue'
import ASN_LIST from '@/graphql/ASNS.gql'
import ASN_CREATE from '@/graphql/ASNCreate.gql'
import MANUFACTURING_ORDER_LIST from '@/graphql/ManufacturingOrders.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'AdvanceShippingNotificationList',
  components: { MessageBox, AdvanceShippingNotificationEditDialog },
  props: {},
  data: () => ({
    dialog: false,
    filters: false,
    searchOrder: '',
    searchASN: '',
    searchManufacturer: '',
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    asns: { items: [] },
    advanceShippingNotifications: { items: [] },
    batchAdvanceShippingNotifications: { items: [] },
    warehouses: { items: [] },
    manufacturingOrders: { items: [] },
    manufacturers: { items: [] },
    editMode: false,
    search: '',
    defaultItem: {
      id: '',
      manufacturingOrder: {
        id: '',
        docketRef: ''
      },
      manufacturerID: null,
      shippingDate: null,
      deliveryDate: null,
      notes: '',
      parentID: null,
      position: 0,
      batchASN: false
    },
    editedItem: {
      id: '',
      manufacturingOrder: {
        id: '',
        docketRef: ''
      },
      manufacturerID: null,
      shippingDate: null,
      deliveryDate: null,
      notes: '',
      parentID: null,
      position: 0,
      batchASN: false
    },
    errorMsg: '',
    processing: false,
    asnStates: ['DRAFT', 'SHIPPING', 'CLOSED'],
    asnState: null
  }),
  apollo: {
    advanceShippingNotifications: {
      query: ASN_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            state: this.asnState,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      result(result) {
        this.page = result.data.advanceShippingNotifications.page
        this.itemsPerPage = result.data.advanceShippingNotifications.pageSize
        this.itemCount = result.data.advanceShippingNotifications.total

        // Set page state
        this.$store.commit('logistics/SET_ASN_PAGE', this.options.page)
        this.$store.commit(
          'logistics/SET_ASN_PAGE_SIZE',
          this.options.itemsPerPage
        )
      }
    },
    batchAdvanceShippingNotifications: {
      query: ASN_LIST,
      variables() {
        return {
          filters: {
            search: this.searchASN,
            batchASN: true,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      update: data => data.advanceShippingNotifications
    },
    manufacturingOrders: {
      query: MANUFACTURING_ORDER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchOrder,
            page: 1,
            pageSize: 10
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'network-only'
    },
    manufacturers: {
      query: MANUFACTURER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchManufacturer,
            page: 1,
            pageSize: 10
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'network-only'
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 1000
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ASN #',
          align: 'left',
          value: 'asnID',
          sortable: false
        },
        {
          text: 'MO #',
          align: 'left',
          value: 'manufacturingOrderID',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'state'
        },
        {
          text: 'Manufacturer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'manufacturerName'
        },
        {
          text: 'Warehouse',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'warehouse'
        },
        {
          text: 'Ordered Qty',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'orderedQuantity'
        },
        {
          text: 'Shipped Qty',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'shippedQuantity'
        },
        {
          text: 'Received Qty',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'receivedQuantity'
        },
        {
          text: 'Shipment Date',
          align: 'left',
          value: 'shippingDate',
          sortable: false,
          filterable: true
        },
        {
          text: 'Arrival Date',
          align: 'left',
          value: 'deliveryDate',
          sortable: false,
          filterable: true
        },
        {
          text: 'Created On',
          value: 'createdAt',
          sortable: false,
          filterable: true
        },
        {
          text: '',
          value: 'controls',
          sortable: false,
          filterable: false
        }
      ]
    },

    authLevel() {
      return this.$store.getters.authLevel('advanceShippingNotifications')
    }
  },
  watch: {
    search() {
      this.options.page = 1
    }
  },
  created() {
    var that = this
    var filters = this.$store.state.logistics.advanceShippingNotificationsPage
    window.onpopstate = function() {
      that.options.page = filters.page
      that.options.itemsPerPage = filters.pageSize
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'AdvanceShippingNotificationDetails',
        params: { asnID: item.id }
      })
    },
    searchManufacturingOrder(val) {
      if (val) {
        this.searchOrder = val
      } else {
        this.searchOrder = null
      }
    },
    searchAdvanceShippingNotification(val) {
      if (val) {
        this.searchASN = val
      } else {
        this.searchASN = null
      }
    },
    searchManufacturers(val) {
      if (val) {
        this.searchManufacturer = val
      } else {
        this.searchManufacturer = null
      }
    },
    addASN() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    close() {
      this.searchManufacturingOrder = ''
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'ASN Added'
          })
          this.$router.push({
            name: 'AdvanceShippingNotificationDetails',
            params: { asnID: result.data.createAdvanceShippingNotification }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
