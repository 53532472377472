<template>
  <div class="page-wrap">
    <manufacturing-order-edit-dialog
      v-if="dialog"
      :dialog="dialog"
      :manufacturing-order="editedItem"
      :payment-terms="paymentTerms"
      :currencies="currencies"
      :manufacturers="manufacturers.items"
      :warehouses="warehouses"
      :seasons="seasons.seasons"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @search-manufacturer="searchManufacturer"
      @close-manufacturing-order-edit-dialog="close"
      @save-manufacturing-order-edit-dialog="save"
    ></manufacturing-order-edit-dialog>
    <!-- <div class="page-header">
      <v-toolbar class="mb-0 b-radius" flat>
        <v-row>
          <v-col>
            <v-btn
              class="ml-2"
              color="primary"
              rounded
              @click="addManufacturingOrder"
            >
              add order
            </v-btn>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>
      </v-toolbar>
    </div> -->

    <div class="main-page-column pt-3">
      <div class="scroll-container pt-0">
        <v-card v-if="filters" class="mb-3">
          <div>
            <v-col cols="12">
              <v-row no-gutters>
                <span>Advanced Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="filters = !filters"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                flat
                dense
                clearable
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                :disabled="
                  !manufacturingOrders ||
                    !manufacturingOrders.items ||
                    manufacturingOrders.length === 0
                "
              >
              </v-select>
            </v-col>
          </div>
        </v-card>

        <v-card flat>
          <v-card-title class="pt-0">
            <v-text-field
              v-model="search"
              hide-details
              flat
              clearable
              background-color="#fff"
              placeholder="Search..."
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
              :disabled="
                !search &&
                  (!manufacturingOrders ||
                    !manufacturingOrders.items ||
                    manufacturingOrders.items.length === 0)
              "
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="manufacturingOrders.items"
            :loading="$apollo.loading"
            :options.sync="options"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
            :hide-default-footer="
              !manufacturingOrders ||
                manufacturingOrders.items.length === 0 ||
                itemCount < options.itemsPerPage + 1
            "
            class="plain-table"
            @click:row="navToDetail"
          >
            <template v-slot:item.id="{ item }">
              <div class="text-truncate primary--text">
                <span
                  v-if="item.status !== 'DRAFT' && item.status !== 'REVIEW'"
                  class="subtitle-1 ml-1"
                  >{{ item.id }}</span
                >
              </div>
            </template>
            <template v-slot:item.docketRef="{ item }">
              <div class="text-truncate primary--text">
                {{ item.docketRef }}
              </div>
            </template>
            <template v-slot:item.manufacturer.name="{ item }">
              <div
                v-if="item.manufacturer"
                class="text-truncate d-inline-block  primary--text"
              >
                {{ item.manufacturer.name }}
              </div>
            </template>

            <template v-slot:item.manufacturerAddress.name="{ item }">
              <div class="text-truncate primary--text">
                {{ item.manufacturerAddress.name }}
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="text-truncate">
                <v-chip
                  pill
                  x-small
                  :color="styleState(item.status)"
                  :text-color="styleText(item.status)"
                  class="upperCaseSpacing"
                >
                  {{ item.status }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.net="{ item }">
              <div class="text-truncate">
                {{
                  (item.net / 100)
                    | currency(item.currency.symbol, 2, {
                      spaceBetweenAmountAndSymbol: true
                    })
                }}
              </div>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <div class="text-truncate">
                {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}
              </div>
            </template>
            <template v-slot:item.season="{ item }">
              <div v-if="item.season" class="text-truncate">
                {{ item.season.longName }} ({{ item.season.shortName }})
              </div>
            </template>
            <template v-slot:item.exFactoryDate="{ item }">
              <div class="text-truncate">
                <span v-if="item.exFactoryDate">{{
                  item.exFactoryDate | moment('DD MMM YYYY')
                }}</span>
              </div>
            </template>
            <template v-slot:item.arrivalDate="{ item }">
              <div class="text-truncate">
                <span v-if="item.arrivalDate">
                  {{ item.arrivalDate | moment('DD MMM YYYY') }}</span
                >
              </div>
            </template>
            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Results"
                caption="Try adjusting your filters to get some results"
              ></message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import ManufacturingOrderEditDialog from '@/modules/production/components/core/ManufacturingOrderEditDialog.vue'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import MANUFACTURING_ORDER_LIST from '@/graphql/ManufacturingOrders.gql'
import MANUFACTURING_ORDER_CREATE from '@/graphql/ManufacturingOrderCreate.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'LogisticsManufacturingOrderList',
  components: { MessageBox, ManufacturingOrderEditDialog },
  data() {
    return {
      searchMan: null,
      filters: false,
      search: null,
      options: { page: 1, itemsPerPage: 10 },
      itemCount: 0,
      selected: [],
      manufacturingOrders: { items: [] },
      dialog: false,
      editMode: false,
      processing: false,
      errorMsg: '',
      manufacturers: { items: [] },
      seasons: { seasons: [] },
      defaultItem: {
        id: null,
        manufacturerID: null,
        paymentTermID: null,
        exchangeRate: null,
        currencyID: null,
        seasonID: null,
        shipperID: null,
        sampleOrder: false,
        net: 0,
        taxPercentage: 0,
        tax: 0,
        discountPercentage: 0,
        discount: 0,
        note: null,
        orderDate: this.$moment()
          .add(10, 'days')
          .format('YYYY-MM-DD'),
        expectedDeliveryStartDate: this.$moment().format('YYYY-MM-DD'),
        expectedDeliveryEndDate: this.$moment()
          .add(1, 'days')
          .format('YYYY-MM-DD')
      },
      editedItem: {
        id: null,
        manufacturerID: null,
        paymentTermID: null,
        exchangeRate: null,
        currencyID: null,
        seasonID: null,
        shipperID: null,
        sampleOrder: false,
        net: 0,
        taxPercentage: 0,
        tax: 0,
        discountPercentage: 0,
        discount: 0,
        note: null,
        orderDate: this.$moment()
          .add(10, 'days')
          .format('YYYY-MM-DD'),
        expectedDeliveryStartDate: this.$moment().format('YYYY-MM-DD'),
        expectedDeliveryEndDate: this.$moment()
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Order #',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'id'
        },
        {
          text: 'Docket Ref',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'docketRef'
        },
        {
          text: 'Manufacturer',
          value: 'manufacturer.name',
          sortable: false,
          filterable: true
        },
        {
          text: 'Location',
          value: 'manufacturerAddress.name',
          sortable: false,
          filterable: true
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          sortable: false
        },
        {
          text: 'Season',
          value: 'season',
          sortable: false,
          filterable: true
        },
        {
          text: 'Qty',
          value: 'total',
          sortable: false,
          filterable: true,
          class: 'font-weight-bold'
        },
        {
          text: 'Cost',
          value: 'net',
          sortable: false,
          filterable: true,
          class: 'font-weight-bold'
        },
        {
          text: 'Ordered By',
          value: 'createdBy',
          sortable: false,
          filterable: true
        },
        {
          text: 'Ex-factory Date',
          value: 'exFactoryDate',
          sortable: false,
          filterable: true
        },
        {
          text: 'Expected Delivery',
          value: 'arrivalDate',
          sortable: false,
          filterable: true
        },
        {
          text: '',
          align: 'left',
          value: 'controls',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    currencies: CURRENCY_LIST,
    paymentTerms: PAYMENT_TERM_LIST,
    warehouses: WAREHOUSE_LIST,
    manufacturingOrders: {
      query: MANUFACTURING_ORDER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        if (result && result.data && result.data.manufacturingOrders) {
          this.page = result.data.manufacturingOrders.page
          this.itemsPerPage = result.data.manufacturingOrders.pageSize
          this.itemCount = result.data.manufacturingOrders.total
        }
      }
    },
    manufacturers: {
      query: MANUFACTURER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchMan,
            page: 1,
            pageSize: 15
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'no-cache'
    },
    seasons: SEASONS
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'LogisticsManufacturingOrderDetail',
        params: {
          manufacturingOrderID: item.id
        }
      })
    },
    styleState: state => {
      var color
      switch (state) {
        case 'PROPOSALREJECTED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'REVIEWREJECTED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'CANCELED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'PENDINGCHANGES':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'PROPOSALSEND':
          color = 'success lighten-3 font-weight-medium'
          break
        case 'SHIPPED':
          color = 'success lighten-3 font-weight-medium'
          break
        case 'APPROVED':
          color = 'info lighten-2 font-weight-medium'
          break
        case 'PROPOSALACCEPTED':
          color = 'info lighten-2 font-weight-medium'
          break
        default:
          color = 'grey lighten-2 font-weight-medium'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'PROPOSALREJECTED':
          color = 'error darken-3'
          break
        case 'REVIEWREJECTED':
          color = 'error darken-3'
          break
        case 'CANCELED':
          color = 'error darken-3'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'PROPOSALSEND':
          color = 'success'
          break
        case 'SHIPPED':
          color = 'success'
          break
        case 'APPROVED':
          color = 'info darken-1'
          break
        case 'PROPOSALACCEPTED':
          color = ' info darken-1'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    },
    searchManufacturer(input) {
      this.searchMan = input
    },
    addManufacturingOrder() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      // var vm = this
      this.errorMsg = ''
      this.processing = true

      // Set default payment term if manufacturer has none
      // if (!item.paymentTermID) {
      //   let term = this._.find(
      //     this.paymentTerms,
      //     item => item.defaultPaymentTerm
      //   )

      //   item.paymentTermID = term.id
      // }

      delete item.id

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_CREATE,
          variables: {
            input: item
          }
          // update: (store, { data: { createManufacturingOrder } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: MANUFACTURING_ORDER_LIST,
          //     variables: {
          //       filters: {
          //         search: vm.search,
          //         page: 1,
          //         pageSize: 15
          //       }
          //     }
          //   })

          //   if (!data.manufacturingOrders.items)
          //     data.manufacturingOrders.items = []

          //   // Add our requirement from the mutation to the end
          //   data.manufacturingOrders.items.unshift(createManufacturingOrder)
          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: MANUFACTURING_ORDER_LIST,
          //     variables: {
          //       filters: {
          //         search: vm.search,
          //         page: 1,
          //         pageSize: 15
          //       }
          //     },
          //     data
          //   })
          // }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Manufacturing Order Added'
          })

          this.$router.push({
            name: 'LogisticsManufacturingOrderDetail',
            params: {
              manufacturingOrderID: result.data.createManufacturingOrder.id
            }
          })
        })
        .catch(err => {
          if (err && err.graphQLErrors && err.graphQLErrors.length > 0) {
            this.errorMsg = err.graphQLErrors[0].message
          } else {
            this.errorMsg = 'General Server Error'
          }

          this.processing = false
        })
    }
  }
}
</script>

<style></style>
