<template>
  <div class="page-wrap">
    <div
      v-if="
        (!$apollo.loading && search) ||
          !stockItems ||
          !stockItems.items ||
          stockItems.items.length > 0
      "
      class="page-header"
    >
      <v-toolbar class="mb-0 b-radius" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <!-- <v-col>
            <v-btn
              class="ml-2"
              color="primary lighten-2"
              rounded
              small
              @click="addStockItem"
            >
              add stockItem
            </v-btn>
          </v-col> -->
          <v-col cols="4" md="4" lg="4" xl="3" class="pt-4">
            <v-autocomplete
              v-model="warehouseID"
              :items="warehouses.items"
              label="Warehouse"
              placeholder="Select Warehouse"
              item-value="id"
              item-text="name"
              hide-details
              flat
              dense
              clearable
              outlined
              background-color="#fff"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" md="4" lg="4" xl="3" class="pt-4">
            <v-dialog v-model="adjustmentDialog" persistent max-width="550">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-3"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                >
                  Upload Stock File
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline primary--text mb-3"
                  >Upload Stock Adjustment File</v-card-title
                >
                <v-card-text>
                  <div v-if="errorMsg !== ''" class="mb-3 pb-0">
                    <v-alert type="error" class="mb-0">
                      {{ errorMsg }}
                    </v-alert>
                  </div>
                  <v-form
                    ref="adjustmentForm"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent
                  >
                    <v-row class="mt-2">
                      <v-col cols="12">
                        <v-alert type="info">
                          <p>
                            Minimal required fields in the CSV file are:
                          </p>
                          <p class="font-weight-bold">
                            barcode, available_quantity, physical_quantity
                          </p>
                          <p>Other fields may be set but will be ignored.</p>
                        </v-alert>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="adjustmentWarehouse"
                          :items="warehouses.items"
                          :rules="requiredSelectRule"
                          label="Warehouse"
                          placeholder="Select Warehouse"
                          item-value="id"
                          item-text="name"
                          hide-details
                          flat
                          dense
                          outlined
                          background-color="#fff"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          v-model="file"
                          :rules="requiredRule"
                          accept="text/csv, application/vnd.ms-excel"
                          label="Upload File"
                          placeholder="Select your image"
                          prepend-inner-icon="mdi-image-outline"
                          :prepend-icon="null"
                          outlined
                          dense
                          hide-details="auto"
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip color="info accent-4" dark small>
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn
                    color="grey darken-1"
                    text
                    rounded
                    :disabled="processing"
                    @click="cancelUpload"
                    >Cancel</v-btn
                  >
                  <v-btn
                    :loading="processing"
                    color="primary lighten-2"
                    rounded
                    @click="uploadFile"
                    >Upload</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-spacer></v-spacer>
          <v-col class="text-right" cols="4">
            <div>{{ itemCount }}</div>
            <div class="caption grey--text">products</div>
          </v-col>
        </v-row>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <stock-item-edit-dialog
        :dialog="dialog"
        :stock-item="editedItem"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-stock-item-edit-dialog="close"
        @save-stock-item-edit-dialog="quickAdjust"
      ></stock-item-edit-dialog>

      <stock-transfer-dialog
        :dialog="stockTranferDialog"
        :stock-item="stockTransferItem"
        :warehouses="warehouses"
        :processing="processing"
        :error-msg="errorMsg"
        @close-stock-transfer-dialog="close"
        @save-stock-transfer-dialog="transferStock"
      ></stock-transfer-dialog>
      <div class="scroll-container pt-0">
        <v-card flat>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="search"
                  hide-details
                  outlined
                  dense
                  clearable
                  background-color="#fff"
                  placeholder="Search..."
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="4">
                <v-select
                  v-model="searchProp"
                  :background-color="'#fff'"
                  :items="searchProps"
                  prefix="by"
                  hide-details
                  outlined
                  dense
                  autocomplete="off"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :loading="$apollo.loading"
            :headers="computedHeaders"
            :items="stockList"
            :options.sync="options"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            fixed-header
            :hide-default-header="
              !$apollo.loading &&
                !search &&
                (!stockItems ||
                  !stockItems.items ||
                  stockItems.items.length === 0)
            "
            :hide-default-footer="
              !stockItems || itemCount < options.itemsPerPage + 1
            "
          >
            <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt" class="text-no-wrap">{{
                item.updatedAt | moment('from', 'now')
              }}</span>
            </template>
            <template v-slot:item.style="{ item }">
              <span
                v-if="item.style"
                class="grey--text text--darken-3 font-weight-medium text-no-wrap"
                >{{ item.style.name }}</span
              >
            </template>
            <template v-slot:item.size="{ item }">
              <v-chip
                v-if="item.size"
                pill
                x-small
                color="secondary lighten-1 font-weight-bold"
                text-color="secondary darken-2"
                >{{ item.size.name }}</v-chip
              >
            </template>
            <template v-slot:item.warehouse="{ item }">
              <v-chip
                v-if="item.warehouse"
                pill
                x-small
                color="info lighten-3 font-weight-bold"
                text-color="info darken-1"
                light
                >{{ item.warehouse.name }}</v-chip
              >
              <v-chip
                v-else
                pill
                x-small
                color="info lighten-3 font-weight-bold"
                text-color="info darken-2"
                light
                >Global</v-chip
              >
            </template>
            <!-- <template v-slot:item.availableQuantity="{ item }">
              <span class="font-weight-medium">{{
                item.availableQuantity
              }}</span>
            </template> -->

            <template v-slot:item.controls="{ item }">
              <div class="text-no-wrap">
                <v-btn
                  color="info"
                  text
                  small
                  rounded
                  :to="{
                    name: 'LogisticsStockItemDetail',
                    params: {
                      styleID: item.style.id,
                      productSizeID: item.size.id
                    }
                  }"
                  >view</v-btn
                >
                <v-btn
                  v-if="stockAdjustAuthLevel === 2 && warehouseID"
                  color="grey"
                  class="ml-2"
                  text
                  small
                  rounded
                  @click="adjustStockItem(item)"
                  >edit stock</v-btn
                >
                <v-btn
                  v-if="
                    stockAdjustAuthLevel === 2 &&
                      parseInt(item.availableQuantity) > 0 && warehouseID
                  "
                  color="grey"
                  class="ml-2"
                  text
                  rounded
                  small
                  @click="openStockTransferDialog(item)"
                  >transfer</v-btn
                >
              </div>
            </template>
            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No StockItems"
                :caption="
                  search
                    ? 'Try adjusting your search to get some results'
                    : 'Create products to generate stock'
                "
              >
                <!-- <v-btn
                  v-if="!search"
                  class="ml-2"
                  color="primary lighten-2"
                  rounded
                  small
                  @click="addStockItem"
                >
                  add stockItem
                </v-btn> -->
              </message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
import StockItemEditDialog from '@/modules/logistics/components/core/StockItemEditDialog.vue'
import StockTransferDialog from '@/modules/logistics/components/core/StockTransferDialog.vue'
import STOCK_ITEM_LIST from '@/graphql/StockItems.gql'
import STOCK_ITEM_UPDATE from '@/graphql/StockItemUpdate.gql'
import STOCK_ITEM_UPDATE_UPLOAD from '@/graphql/StockItemUpdateUpload.gql'
import STOCK_TRANSFER from '@/graphql/StockTransfer.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'LogisticsStockList',
  components: { MessageBox, StockItemEditDialog, StockTransferDialog },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    searchProp: 'Name',
    searchProps: ['Name', 'Barcode', 'Style Number'],
    search: null,
    nameSearch: null,
    styleSearch: null,
    barcodeSearch: null,
    adjustmentDialog: false,
    processing: false,
    adjustmentWarehouse: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    file: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    stockItems: { items: [] },
    warehouses: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    warehouseID: null,
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'style'
      },
      {
        text: 'Color',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'style.seasonColor'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'size'
      },
      {
        text: 'Production Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'productionQuantity'
      },
      {
        text: 'Picking Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'pickingQuantity'
      },
      // {
      //   text: 'Reserved Qty',
      //   align: 'right',
      //   sortable: false,
      //   filterable: true,
      //   class: ' font-weight-bold',
      //   value: 'reservedQuantity'
      // },
      {
        text: 'Available Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        class: ' font-weight-bold',
        value: 'availableQuantity'
      },
      {
        text: 'Physical Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'physicalQuantity'
      },
      {
        text: '',
        value: 'controls',
        sortable: false,
        filterable: true
      }
    ],
    defaultItem: {
      productionQty: null,
      pickingQty: null,
      availableQty: null,
      physicalQty: null,
      notes: ''
    },
    editedItem: {
      productionQty: null,
      pickingQty: null,
      availableQty: null,
      physicalQty: null,
      notes: ''
    },

    stockTranferDialog: false,
    stockTransferItem: {
      fromWarehouseID: null,
      toWarehouseID: null,
      barcode: null,
      quantity: null,
      notes: ''
    }
  }),
  apollo: {
    stockItems: {
      query: STOCK_ITEM_LIST,
      variables() {
        return {
          filters: {
            nameSearch: this.nameSearch,
            styleID: this.styleSearch,
            barcode: this.barcodeSearch,
            warehouseID: this.warehouseID,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.stockItems.page
        this.itemsPerPage = result.data.stockItems.pageSize
        this.itemCount = result.data.stockItems.total
      }
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 10000
          }
        }
      }
    }
  },
  computed: {
    computedHeaders() {
      var items = this.headers
      if (!this.warehouseID)
        items = this.headers.filter(item => item.value !== 'updatedAt')
      return items
    },
    currentWarehouse() {
      return this._.find(
        this.warehouses.items,
        item => item.id === this.warehouseID
      )
    },
    stockAdjustAuthLevel() {
      return this.$store.getters.authLevel('stock_update')
    },
    stockList() {
      // var that = this
      // if(this.stockItems?.items?.length > 0) {
      //   let arr = this._.sortBy(that.stockItems.items, (item) => parseInt(item.size.promeseCode))
      //   return arr
      // } else {
      //   return []
      // }
      var that = this

      if(this.stockItems?.items?.length > 0) {
        var items = this._.filter(that.stockItems.items, (l) => l.size)
        that._.filter(items, (item) => that._.sortBy(item.size, (size) => parseInt(size.promeseCode)))
        return items
      } else {
        return []
      }
    }
  },
  watch: {
    search(val) {
      // Reset to first page on search
      this.options.page = 1
      this.nameSearch = null
      this.styleSearch = null
      this.barcodeSearch = null

      if (val) {
        switch (this.searchProp) {
          case 'Style Number':
            this.nameSearch = null
            this.styleSearch = val
            this.barcodeSearch = null
            break
          case 'Barcode':
            this.nameSearch = null
            this.styleSearch = null
            this.barcodeSearch = val
            break
          case 'Name':
            this.nameSearch = val
            this.styleSearch = null
            this.barcodeSearch = null
            break
          default:
            break
        }
      }
    },
    searchProp() {
      this.options.page = 1
      this.search = null
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'LogisticsStockItemDetail',
        params: { styleID: item.style.id, productSizeID: item.size.id }
      })
    },
    adjustStockItem(item) {
      this.editedItem = this._.extend(this.defaultItem, item)
      this.editedItem.warehouseID = this.warehouseID
      this.editedItem.productionQty = item.productionQuantity.toString()
      this.editedItem.pickingQty = item.pickingQuantity.toString()
      this.editedItem.availableQty = item.availableQuantity.toString()
      this.editedItem.physicalQty = item.physicalQuantity.toString()
      this.dialog = true
    },
    openStockTransferDialog(item) {
      this.stockTransferItem = {
        fromWarehouseID: this.warehouseID,
        warehouseProviderID: this.currentWarehouse.provider.id,
        toWarehouseID: null,
        barcode: item.barcode,
        availableQuantity: item.availableQuantity,
        quantity: null,
        notes: ''
      }

      this.stockTranferDialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false

      this.stockTranferDialog = false
      this.stockTransferItem = {
        fromWarehouseID: null,
        toWarehouseID: null,
        barcode: null,
        quantity: null,
        notes: ''
      }
    },
    quickAdjust(data) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: STOCK_ITEM_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.$apollo.queries.stockItems.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Stock Item Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    transferStock(data) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: STOCK_TRANSFER,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.$apollo.queries.stockItems.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Stock Transfered'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    cancelUpload() {
      this.adjustmentDialog = false
      this.file = null
      this.adjustmentWarehouse = null
      this.$refs.adjustmentForm.resetValidation()
      this.processing = false
    },
    uploadFile() {
      this.valid = true
      this.processing = true

      if (this.$refs.adjustmentForm.validate()) {
        var data = {
          warehouseID: this.adjustmentWarehouse,
          file: this.file
        }

        this.$apollo
          .mutate({
            mutation: STOCK_ITEM_UPDATE_UPLOAD,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message:
                'File is being processed. You will receive an email when done.',
              timeout: 10000
            })

            this.cancelUpload()
          })
          .catch(err => {
            this.processing = false
            console.log(err)
          })
      }
    }
  }
}
</script>

<style></style>
