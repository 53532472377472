var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('product-detail-header',{attrs:{"product":_vm.seasonProduct,"product-assets":_vm.productAssets,"product-materials":_vm.productMaterials,"product-manufacturers":_vm.productManufacturers,"selected-season":_vm.selectedSeason,"auth-level":_vm.authLevel},on:{"set-season":_vm.setSelectedSeason,"change-state":_vm.changeState},scopedSlots:_vm._u([{key:"menu-items",fn:function(){return [_c('v-tabs',{staticStyle:{"width":"80%"},attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductDetail',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"exact":"","disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":!_vm.product || !_vm.product.sizes || _vm.product.sizes.length === 0}},[_vm._v("Details")])],1),(_vm.techPackAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductDesignAssets',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":!_vm.techPacks || _vm.techPacks.length === 0}},[_vm._v(" Tech Packs ")])],1):_vm._e(),(_vm.styleSpecAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductProductionAssets',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":!_vm.stylePacks || _vm.stylePacks.length === 0}},[_vm._v(" Style Specs ")])],1):_vm._e(),(_vm.manufacturerAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductManufacturers',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":!_vm.productManufacturers || _vm.productManufacturers.length === 0}},[_vm._v("Manufacturers")])],1):_vm._e(),(_vm.materialAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductMaterials',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":!_vm.productMaterials || _vm.productMaterials.length === 0}},[_vm._v("Materials")])],1):_vm._e(),(_vm.logisticsAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductCustoms',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","value":_vm.customCheck}},[_vm._v("Logistics/Customs")])],1):_vm._e(),(_vm.costingAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductCosts',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"info","dot":"","value":_vm.costCheck}},[_vm._v("Costs")])],1):_vm._e(),(_vm.pricingAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductPricing',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_c('v-badge',{attrs:{"color":"info","dot":"","value":_vm.priceCheck}},[_vm._v("Pricing")])],1):_vm._e(),(_vm.imageAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductImages',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_vm._v("Images")]):_vm._e(),(_vm.inventoryAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductInventory',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_vm._v("Inventory")]):_vm._e(),(_vm.seasonAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductSeasons',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_vm._v("Seasons")]):_vm._e(),(_vm.authFlowAuthLevel > 0)?_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductAuditFlows',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason }
          },"disabled":!_vm.selectedSeason}},[_vm._v("Authorisation")]):_vm._e(),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'LogisticsProductChangeLog',
            params: { productID: _vm.$route.params.productID },
            query: { season: _vm.selectedSeason },
          },"disabled":!_vm.selectedSeason}},[_vm._v("Change Log")])],1)]},proxy:true}])}),_c('div',{staticClass:"main-page-column"},[_c('div',{staticClass:"scroll-container pt-0"},[(_vm.selectedSeason && !this.$apollo.loading)?_c('router-view',{attrs:{"product":_vm.seasonProduct,"users":_vm.users,"colors":_vm.colors,"manufacturer-list":_vm.manufacturers,"departments":_vm.departments,"categories":_vm.productCategories,"article-groups":_vm.articleGroups,"sub-article-groups":_vm.subArticleGroups,"style-types":_vm.styleTypes,"size-groups":_vm.sizeGroups,"current-user":_vm.currentUser,"auth-level":_vm.authLevel,"selected-season":_vm.selectedSeason,"currency-list":_vm.currencyList,"seasons":_vm.seasons.seasons,"price-markups":_vm.priceMarkups,"warehouses":_vm.warehouses},on:{"change-state":_vm.changeState}}):_vm._e(),(!_vm.selectedSeason && !this.$apollo.loading)?_c('div',[(_vm.product)?_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Season Selected","caption":"Please select a season to continue"}},[(_vm.product && _vm.product.seasons && _vm.product.seasons.length > 0)?_c('v-autocomplete',{staticClass:"highlight-control info--text mt-3",attrs:{"hide-details":"","outlined":"","flat":"","dense":"","background-color":"white","label":"Season","placeholder":"Select Season","item-value":"id","filter":_vm.seasonFilter,"color":"info","item-color":"info","items":_vm.product.seasons},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.shortName))])]}}],null,false,2295589170),model:{value:(_vm.selectedSeason),callback:function ($$v) {_vm.selectedSeason=$$v},expression:"selectedSeason"}}):_vm._e()],1):_vm._e()],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }