<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="contactForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.firstName"
                      label="First Name"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.lastName"
                      label="Last Name"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.jobTitle"
                      label="Jobtitle"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                      outlined
                      dense
                      hide-details="auto"
                      type="email"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.phone"
                      label="Phone"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import country from 'country-list'
import { countries } from 'country-data-list'

export default {
  name: 'ContactEditDialog',
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Contact' : 'New Contact'
    },
    countryList() {
      return this._.sortBy(countries.all, 'name')
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.contact)
    }
  },
  methods: {
    close() {
      // this.$refs.contactForm.reset()
      this.$emit('close-contact-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.contactForm.resetValidation()
      })
    },
    save() {
      var that = this
      if (this.$refs.contactForm.validate()) {
        var data = {
          id: this.item.id,
          shipperID: this.item.shipperID || null,
          warehouseID: this.item.warehouseID || null,
          warehouseProviderID: this.item.warehouseProviderID || null,
          firstName: this.item.firstName,
          lastName: this.item.lastName,
          jobTitle: this.item.jobTitle,
          email: this.item.email,
          phone: this.item.phone
        }

        if (!this.editMode) {
          delete data.id
        } else {
          delete data.shipperID
          delete data.warehouseID
          delete data.warehouseProviderID
        }

        this.$emit('save-contact', data)
        this.$nextTick(() => {
          that.$refs.contactForm.resetValidation()
        })
      }
    }
  }
}
</script>

<style></style>
