var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(_vm.dialog)?_c('manufacturing-order-edit-dialog',{attrs:{"dialog":_vm.dialog,"manufacturing-order":_vm.editedItem,"payment-terms":_vm.paymentTerms,"currencies":_vm.currencies,"manufacturers":_vm.manufacturers.items,"warehouses":_vm.warehouses,"seasons":_vm.seasons.seasons,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"search-manufacturer":_vm.searchManufacturer,"close-manufacturing-order-edit-dialog":_vm.close,"save-manufacturing-order-edit-dialog":_vm.save}}):_vm._e(),_c('div',{staticClass:"main-page-column pt-3"},[_c('div',{staticClass:"scroll-container pt-0"},[(_vm.filters)?_c('v-card',{staticClass:"mb-3"},[_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_vm._v("Advanced Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.filters = !_vm.filters}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","flat":"","dense":"","clearable":"","label":"producer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","disabled":!_vm.manufacturingOrders ||
                  !_vm.manufacturingOrders.items ||
                  _vm.manufacturingOrders.length === 0}})],1)],1)]):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"hide-details":"","flat":"","clearable":"","background-color":"#fff","placeholder":"Search...","prepend-inner-icon":"mdi-magnify","autocomplete":"off","disabled":!_vm.search &&
                (!_vm.manufacturingOrders ||
                  !_vm.manufacturingOrders.items ||
                  _vm.manufacturingOrders.items.length === 0)},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"items":_vm.manufacturingOrders.items,"loading":_vm.$apollo.loading,"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"hide-default-footer":!_vm.manufacturingOrders ||
              _vm.manufacturingOrders.items.length === 0 ||
              _vm.itemCount < _vm.options.itemsPerPage + 1},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[(item.status !== 'DRAFT' && item.status !== 'REVIEW')?_c('span',{staticClass:"subtitle-1 ml-1"},[_vm._v(_vm._s(item.id))]):_vm._e()])]}},{key:"item.docketRef",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[_vm._v(" "+_vm._s(item.docketRef)+" ")])]}},{key:"item.manufacturer.name",fn:function(ref){
              var item = ref.item;
return [(item.manufacturer)?_c('div',{staticClass:"text-truncate d-inline-block  primary--text"},[_vm._v(" "+_vm._s(item.manufacturer.name)+" ")]):_vm._e()]}},{key:"item.manufacturerAddress.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[_vm._v(" "+_vm._s(item.manufacturerAddress.name)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-chip',{staticClass:"upperCaseSpacing",attrs:{"pill":"","x-small":"","color":_vm.styleState(item.status),"text-color":_vm.styleText(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.net",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm._f("currency")((item.net / 100),item.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])]}},{key:"item.createdBy",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.createdBy.firstName + ' ' + item.createdBy.lastName)+" ")])]}},{key:"item.season",fn:function(ref){
              var item = ref.item;
return [(item.season)?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.season.longName)+" ("+_vm._s(item.season.shortName)+") ")]):_vm._e()]}},{key:"item.exFactoryDate",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.exFactoryDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.exFactoryDate,'DD MMM YYYY')))]):_vm._e()])]}},{key:"item.arrivalDate",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[(item.arrivalDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.arrivalDate,'DD MMM YYYY')))]):_vm._e()])]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Results","caption":"Try adjusting your filters to get some results"}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }