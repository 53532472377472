<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0 pr-4">
        <advance-shipping-notification-edit-dialog
          :dialog="dialog"
          :asn="editedItem"
          :asns="advanceShippingNotifications.items"
          :manufacturers="manufacturers.items"
          :manufacturing-orders.sync="manufacturingOrders.items"
          :warehouses="warehouses.items"
          :edit-mode="editMode"
          :processing="processing"
          :error-msg="errorMsg"
          @search-manufacturing-order="searchManufacturingOrder"
          @search-asn="searchAdvanceShippingNotification"
          @search-manufacturers="searchManufacturers"
          @close-asn-edit-dialog="close"
          @save-asn-edit-dialog="save"
        ></advance-shipping-notification-edit-dialog>
        <v-dialog v-model="removeDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove ASN</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will remove the advance shipping notification and can't be
                undone.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text @click="removeDialog = false"
                >Cancel</v-btn
              >
              <v-btn color="error" text :loading="processing" @click="removeASN"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="removeableChildDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Child ASN</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will remove the advance shipping notification and adjust
                the batch quantities.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                @click="removeableChildDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="error"
                text
                :loading="processing"
                @click="removeChild"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="removeLineDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Line</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will remove the line and can't be undone.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                @click="removeLineDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="error"
                text
                :loading="processing"
                @click="removeASNLine"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="editLineItemDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >Edit Line</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" dense>
                {{ errorMsg }}
              </v-alert>
            </div>
            <div class="pa-3 pb-0">
              <v-alert type="info" dense>
                <span>Unfulfilled Quantity on the MO is </span>
                <strong>{{ editedLineItem.orderedQuantity }}</strong>
              </v-alert>
            </div>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedLineItem.shippedQuantity"
                    label="Shipped Quantity"
                    hide-details="auto"
                    autocomplete="off"
                    dense
                    outlined
                    persistent-hint
                    :min="1"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" rounded text @click="close"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                small
                rounded
                :loading="processing"
                :disabled="
                  !editedLineItem.shippedQuantity ||
                    parseInt(editedLineItem.shippedQuantity) <= 0
                "
                @click="updateLineItem"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="submitDialog" persistent max-width="450">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >Submit ASN</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3">
              <v-alert type="error">
                {{ errorMsg }}
              </v-alert>
            </div>
            <div
              v-if="
                manufacturingOrder && manufacturingOrder.status === 'SHIPPED'
              "
              class="pa-3 pb-0"
            >
              <v-alert type="info">
                The Manufacturer order has already been shipped. Might this be a
                duplicate ASN?
              </v-alert>
            </div>
            <div
              v-if="
                manufacturingOrder &&
                  manufacturingOrder.status !== 'PROPOSALACCEPTED' &&
                  manufacturingOrder.status !== 'SHIPPED'
              "
              class="pa-3 pb-0"
            >
              <v-alert type="info">
                The Manufacturer order has not been accepted by the Manufacturer
                yet.
              </v-alert>
            </div>
            <v-card-text
              v-if="
                manufacturingOrder &&
                  manufacturingOrder.status === 'PROPOSALACCEPTED' &&
                  manufacturingOrder.status !== 'SHIPPED'
              "
            >
              <p>
                This will submit the advance shipping notification to the
                warehouse and can't be undone.
              </p>
            </v-card-text>
            <v-card-text v-if="asn && asn.batchASN">
              <p>
                This will submit the batch advance shipping notification to the
                warehouse and can't be undone.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                rounded
                @click="submitDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                rounded
                small
                :loading="processing"
                @click="submitASN"
                >Send To Warehouse</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="reSubmitDialog" persistent max-width="450">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >Resend ASN</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3">
              <v-alert type="error">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will resend the advance shipping notification to the
                warehouse. Only do this if an error has occured with the
                warehouse message exchange.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                rounded
                @click="reSubmitDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                rounded
                small
                :loading="processing"
                @click="reSubmitASN"
                >Send To Warehouse</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="scroll-container pt-0">
          <v-row>
            <v-col v-if="asn" cols="12">
              <v-card flat class="pt-3 pb-6">
                <v-card-title class="mb-3 grey--text"
                  >ASN No.
                  <span class="primary--text pl-2"> {{ asn.asnID }}</span
                  ><v-spacer></v-spacer>
                  <v-btn
                    v-if="asn.state === 'SHIPPING' && !asn.parentID"
                    color="primary"
                    rounded
                    @click="reSubmitDialog = true"
                  >
                    Resubmit
                  </v-btn>
                  <v-menu
                    v-if="asn.state === 'DRAFT'"
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    class="mt-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        rounded
                        color="primary"
                        v-bind="attrs"
                        v-on.prevent="on"
                        >actions</v-btn
                      >
                    </template>

                    <v-list>
                      <v-list-item @click="editASN">
                        Edit ASN
                      </v-list-item>
                      <v-list-item
                        v-if="!asn.parentID"
                        @click="submitDialog = true"
                      >
                        Send to Warehouse
                      </v-list-item>
                      <v-list-item @click="openRemoveDialog">
                        Remove ASN
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" class="mb-3">
                      <v-chip v-if="asn.state === 'DRAFT'" x-small>{{
                        asn.state
                      }}</v-chip>
                      <v-chip
                        v-if="asn.state === 'SHIPPING'"
                        x-small
                        color="success lighten-3 font-weight-medium"
                        text-color="success"
                        >{{ asn.state }}</v-chip
                      >
                      <v-chip
                        v-if="asn.state === 'ALLOCATING'"
                        x-small
                        color="info lighten-2 font-weight-medium"
                        text-color="info darken-1"
                        >{{ asn.state }}</v-chip
                      >
                      <v-chip
                        v-if="asn.state === 'CLOSED'"
                        x-small
                        color="warning lighten-1 font-weight-medium"
                        text-color="warning darken-3"
                        >{{ asn.state }}</v-chip
                      >
                      <v-chip
                        v-if="asn.batchASN"
                        x-small
                        color="info lighten-2 font-weight-medium"
                        text-color="info darken-3"
                        class="ml-3"
                        >BATCH</v-chip
                      >
                      <v-chip
                        v-if="asn.parentID"
                        x-small
                        color="teal lighten-4 font-weight-medium"
                        text-color="teal darken-3"
                        class="ml-3"
                        >CHILD</v-chip
                      >
                      <v-btn
                        v-if="asn.parentID"
                        :to="{
                          name: 'AdvanceShippingNotificationDetails',
                          params: { asnID: asn.parentID }
                        }"
                        x-small
                        rounded
                        text
                        color="info"
                        class="ml-3"
                        >view batch asn</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Manufacturer</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="asn.manufacturerID"
                            class="font-weight-medium grey--text text--darken-2"
                            ><router-link
                              :to="{
                                name: 'LogisticsManufacturerDetail',
                                params: { manufacturerID: asn.manufacturerID }
                              }"
                              >{{ asn.manufacturerName }}</router-link
                            ></span
                          >
                          <span
                            v-else
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ asn.manufacturerName }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="!asn.batchASN" class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Manufacturing Order ID</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ asn.manufacturingOrderID }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Shipping Date</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ asn.shippingDate | moment('DD MMM YY') }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Delivery Date</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ asn.deliveryDate | moment('DD MMM YY') }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Warehouse</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div class="text-truncate">
                            <span
                              v-if="asn.warehouse"
                              class="font-weight-medium grey--text text--darken-2"
                              >{{ asn.warehouse.name }}</span
                            ><span
                              v-if="asn.warehouse && asn.warehouse.provider"
                              class="pl-3"
                              >({{ asn.warehouse.provider.name }})</span
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col v-if="asn.batchASN" cols="12"
              ><v-card flat class="px-3 mb-3">
                <v-row>
                  <v-col v-if="asn.state === 'DRAFT'" cols="6" sm="4">
                    <v-autocomplete
                      v-model="selectedASN"
                      :items="unusedAsns"
                      :search-input.sync="searchASNS"
                      autocomplete="off"
                      label="Child ASN"
                      hide-details="auto"
                      outlined
                      item-text="asnID"
                      clearable
                      dense
                      return-object
                      :disabled="editMode && asn.state !== 'DRAFT'"
                    >
                      <template v-slot:selection="data">
                        <span class="font-weight-medium"
                          >{{ data.item.asnID }} &nbsp;</span
                        >
                        <span v-if="data.item.manufacturerName !== ''">
                          - {{ data.item.manufacturerName }}</span
                        >
                      </template>
                      <template v-slot:item="data">
                        <span class="font-weight-medium"
                          >{{ data.item.asnID }} &nbsp;</span
                        >
                        <span v-if="data.item.manufacturerName !== ''">
                          - {{ data.item.manufacturerName }}</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="asn.state === 'DRAFT'" cols="3" sm="4">
                    <v-btn
                      :disabled="!selectedASN"
                      :loading="processing"
                      small
                      rounded
                      color="primary lighten-2"
                      class="mt-1"
                      @click="addChildAsn"
                      >Add Asn(s)</v-btn
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-row justify="end">
                      <div class="py-3 mr-3">
                        <span class="grey--text caption">Total Qty: </span
                        ><span>{{ totalQty }}</span>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card></v-col
            >
            <v-col v-if="asn.batchASN" cols="12"
              ><v-card flat class="px-3">
                <v-row>
                  <v-col cols="12" md="6" class="grey--text text-h6 pb-0"
                    >Child Asns</v-col
                  >
                </v-row>
                <v-row v-if="asn.state === 'DRAFT'">
                  <v-col cols="12" md="6" class="pb-0">
                    <v-alert type="info" dense border="left" text>
                      Drag the children in the order of priority for allocation.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row v-if="children">
                  <v-col cols="12" md="6" class="pt-0">
                    <v-list>
                      <draggable
                        :list="children"
                        ghost-class="ghost"
                        @update="updatePosition"
                        handle=".handle"
                      >
                        <v-list-item
                          v-for="element in children"
                          :key="element.id"
                          class="py-2"
                          style="min-height: auto; border-bottom: 1px solid #ddd"
                        >
                          <v-icon left class="handle">mdi-swap-vertical</v-icon>
                          <div>
                            <div>
                              {{ element.asnID }} (MO
                              {{ element.manufacturingOrderID }})
                              {{ element.manufacturerName }}
                            </div>

                            <div class="grey--text">
                              {{ element.warehouse.name }} -
                              {{ element.warehouse.provider.name }}
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn
                            :to.prevent="{
                              name: 'AdvanceShippingNotificationDetails',
                              params: { asnID: element.id }
                            }"
                            class="ml-3"
                            x-small
                            rounded
                            text
                            color="info"
                            >view</v-btn
                          >
                          <v-btn
                            @click="openRemoveChildDialog(element)"
                            v-if="asn.state === 'DRAFT'"
                            class="ml-3"
                            x-small
                            rounded
                            text
                            color="grey lighten-1"
                            >delete</v-btn
                          >
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-col>

                  <v-spacer></v-spacer>
                </v-row> </v-card
            ></v-col>
            <v-col v-if="!asn.batchASN" cols="12"
              ><v-card flat class="px-3">
                <v-row>
                  <v-col v-if="asn.state === 'DRAFT'" cols="6" sm="4">
                    <v-autocomplete
                      v-model="selectedProducts"
                      label="Products"
                      placeholder="select product(s)"
                      item-text="productName"
                      item-value="barcode"
                      return-object
                      autocomplete="off"
                      :items="unusedProducts"
                      dense
                      outlined
                      hide-details
                      clearable
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip v-if="data.index === 0" small>
                          <div class="text-truncate">
                            <span>{{ data.item.productName }} </span>
                            <span class="grey--text darken-1"
                              >({{ data.item.seasonColor }})</span
                            >
                            <span> - {{ data.item.name }}</span>
                          </div>
                        </v-chip>
                        <span v-if="data.index === 1" class="grey--text caption"
                          >(+{{ selectedProducts.length - 1 }} others)</span
                        >
                      </template>
                      <template v-slot:item="data">
                        <div>
                          <span>{{ data.item.productName }} </span>
                          <span class="grey--text darken-1"
                            >({{ data.item.seasonColor }})</span
                          >
                          <span> - {{ data.item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="asn.state === 'DRAFT'" cols="3" sm="4">
                    <v-btn
                      :disabled="
                        !selectedProducts || selectedProducts.length === 0
                      "
                      :loading="processing"
                      small
                      rounded
                      color="primary lighten-2"
                      class="mt-1"
                      @click="addProducts"
                      >Add Product(s)</v-btn
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-row justify="end">
                      <div class="py-3 mt-2 mr-3">
                        <span class="grey--text caption">Total Qty: </span
                        ><span>{{ totalQty }}</span>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card></v-col
            >
            <v-col cols="12"
              ><v-card flat>
                <v-data-table
                  :loading="$apollo.loading"
                  :headers="headers"
                  :items="orderBy(asn.lines, 'productSizeID')"
                  :mobile-breakpoint="null"
                  fixed-header
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.createdAt="{ item }">
                    <span v-if="item.createdAt" class="text-no-wrap">{{
                      item.createdAt | moment('from', 'now')
                    }}</span>
                  </template>
                  <template v-slot:item.deliveryDate="{ item }">
                    <span>{{ item.deliveryDate | moment('DD MMM YY') }}</span>
                  </template>
                  <template v-slot:item.seasonProduct.styleNumber="{ item }">
                    <span
                      >{{
                        item.seasonProduct.styleNumber + item.productSizeName
                      }}
                    </span>
                  </template>

                  <template v-slot:item.seasonProduct="{ item }">
                    <span>{{ item.seasonProduct.name }} </span>
                  </template>

                  <template v-slot:item.controls="data">
                    <v-btn
                      v-if="asn.state === 'DRAFT'"
                      color="info"
                      class="text-lowercase mr-2"
                      text
                      small
                      rounded
                      @click.stop="openEditLineDialog(data.item)"
                      >edit</v-btn
                    >
                    <v-btn
                      v-if="asn.state === 'DRAFT'"
                      color="grey lighten-1"
                      class="text-lowercase"
                      text
                      small
                      rounded
                      @click.stop="openRemoveLineDialog(data.item.id)"
                      >delete</v-btn
                    >
                  </template>

                  <template v-slot:no-data>
                    <message-box title="No Lines Yet..."> </message-box>
                  </template>
                  <template v-slot:loading>
                    <v-skeleton-loader
                      v-for="item in 5"
                      :key="item"
                      type="list-item"
                      class="mx-auto"
                    ></v-skeleton-loader>
                  </template>
                </v-data-table> </v-card
            ></v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
import AdvanceShippingNotificationEditDialog from '@/modules/logistics/components/core/AdvanceShippingNotificationEditDialog.vue'
import ASN from '@/graphql/ASN.gql'
import ASN_UPDATE from '@/graphql/ASNUpdate.gql'
import ASN_SUBMIT from '@/graphql/ASNSubmit.gql'
import ASN_RESUBMIT from '@/graphql/ASNReSubmit.gql'
import ASN_REMOVE from '@/graphql/ASNRemove.gql'
import ASN_LINE_REMOVE from '@/graphql/ASNLineRemove.gql'
import ASN_LINE_CREATE from '@/graphql/ASNLineCreate.gql'
import ASN_LINE_UPDATE from '@/graphql/ASNLineUpdate.gql'
import MANUFACTURING_ORDER from '@/graphql/ManufacturingOrder.gql'
import ASN_LIST from '@/graphql/ASNS.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import { mdiNumeric9PlusCircle } from '@mdi/js'

export default {
  name: 'AdvanceShippingNotificationDetails',
  components: { draggable, MessageBox, AdvanceShippingNotificationEditDialog },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    search: null,
    itemCount: 0,
    searchASN: '',
    searchASNS: '',
    myArray: [],
    searchManufacturer: '',
    removeableChild: null,
    removeableChildDialog: false,
    asn: {},
    children: [],
    advanceShippingNotifications: { items: [] },
    asns: { items: [] },
    manufacturers: { items: [] },
    warehouses: { items: [] },
    selectedASN: null,
    dialog: false,
    submitDialog: false,
    reSubmitDialog: false,
    removeDialog: false,
    removeLineDialog: false,
    editLineItemDialog: false,
    removeableLineID: null,
    editMode: false,
    processing: false,
    searchManufacturingOrder: '',
    manufacturingOrders: { items: [] },
    errorMsg: '',
    selectedProducts: null,
    headers: [
      {
        text: 'Product',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct'
      },
      {
        text: 'Color',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.seasonColor'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'productSizeName'
      },
      {
        text: 'Ordered Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'orderedQuantity'
      },
      {
        text: 'Shipped Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'shippedQuantity'
      },
      {
        text: 'Received',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'receivedQuantity'
      },
      // {
      //   text: 'SKU',
      //   sortable: false,
      //   filterable: true,
      //   value: 'seasonProduct.styleNumber'
      // },
      {
        text: 'Barcode',
        sortable: false,
        filterable: true,
        value: 'barcode'
      },
      {
        text: '',
        sortable: false,
        filterable: false,
        value: 'controls'
      }
    ],
    defaultItem: {
      id: '',
      manufacturingOrder: {
        id: '',
        docketRef: ''
      },
      shippingDate: null,
      deliveryDate: null,
      notes: '',
      parentID: null,
      position: 0,
      batchASN: false
    },
    editedItem: {
      id: '',
      manufacturingOrder: {
        id: '',
        docketRef: ''
      },
      shippingDate: null,
      deliveryDate: null,
      notes: '',
      parentID: null,
      position: 0,
      batchASN: false
    },

    lineItemDialog: false,
    defaultLineItem: {
      id: '',
      parentID: null,
      advanceShippingNotificationID: '',
      notes: '',
      orderedQuantity: 0,
      shippedQuantity: 0
    },
    editedLineItem: {
      id: '',
      parentID: null,
      advanceShippingNotificationID: '',
      notes: '',
      orderedQuantity: 0,
      shippedQuantity: 0
    }
  }),
  apollo: {
    asn: {
      query: ASN,
      variables() {
        return {
          id: this.$route.params.asnID
        }
      },
      update: data => data.advanceShippingNotification,
      result(result) {
        if (!result.data.advanceShippingNotification.lines)
          result.data.advanceShippingNotification.lines = []

        let temp = this._.cloneDeep(
          result.data.advanceShippingNotification.children
        )

        this.children = this._.sortBy(temp, 'position')
      }
    },
    parentASN: {
      query: ASN,
      variables() {
        return {
          id: this.asn?.parentID
        }
      },
      update: data => data.advanceShippingNotification,
      result(result) {
        if (!result.data.advanceShippingNotification.lines)
          result.data.advanceShippingNotification.lines = []

        this.asn.parent = result.data.advanceShippingNotification
      },
      skip() {
        return !this.asn?.parentID
      }
    },
    advanceShippingNotifications: {
      query: ASN_LIST,
      variables() {
        return {
          filters: {
            search: this.searchASN,
            batchASN: true,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200
    },
    asns: {
      query: ASN_LIST,
      variables() {
        return {
          filters: {
            search: this.searchASNS,
            batchASN: false,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'network-only',
      debounce: 200,
      update: data => data.advanceShippingNotifications
    },
    manufacturingOrder: {
      query: MANUFACTURING_ORDER,
      variables() {
        return {
          id: this.asn.manufacturingOrderID
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      skip() {
        return !this.asn || !this.asn.id
      }
    },
    manufacturers: {
      query: MANUFACTURER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchManufacturer,
            page: 1,
            pageSize: 10
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'network-only'
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 1000
          }
        }
      },
      debounce: 200,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    unusedAsns() {
      var vm = this

      var keys =
        vm.asn?.children?.length > 0
          ? vm._.map(vm.asn.children, function(item) {
              return item.id
            })
          : []

      var items = []
      keys.push(this.asn.id)

      vm.asns?.items?.length > 0
        ? vm._.each(vm.asns.items, function(item) {
            if (keys.indexOf(item.id) === -1 && !item.batchASN && !item.parentID) {
              items.push(item)
            }
          })
        : []

      return vm._.orderBy(items, ['asnID'], ['desc'])
    },
    unusedProducts() {
      var vm = this

      var keys =
        vm.asn?.lines && vm.asn.lines.length > 0
          ? vm._.map(vm.asn.lines, function(line) {
              return line.productSizeID
            })
          : []

      var items = []

      vm.manufacturingOrder?.orderLines?.length > 0
        ? vm._.each(vm.manufacturingOrder.orderLines, function(item) {
            vm._.each(item.items, function(subItem) {
              // is there unfullfilledqty and is key known
              if (
                keys.indexOf(subItem.productSize.id) === -1 &&
                subItem.unfulfilledQuantity > 0
              ) {
                items.push({
                  productName: item.product.name,
                  seasonColor: item.product.seasonColor,
                  advanceShippingNotificationID: vm.asn.id,
                  manufacturingOrderLineID: subItem.id,
                  seasonProductID: item.seasonProduct.seasonProductID,
                  productSizeID: subItem.productSize.id,
                  productSizeName: subItem.productSize.name,
                  warehouseID: vm.asn.warehouse.id,
                  name: subItem.productSize.name,
                  barcode: subItem.productSize.barcode,
                  notes: '',
                  orderedQuantity: subItem.unfulfilledQuantity,
                  shippedQuantity: subItem.unfulfilledQuantity,
                  unitPrice: item.unitCost
                })
              }
            })
          })
        : []

      return vm._.sortBy(items, 'barcode')
    },
    totalQty() {
      var vm = this
      var count = 0

      vm._.forEach(vm.asn.lines, line => (count += line.shippedQuantity))

      return count
    }
  },
  methods: {
    openRemoveChildDialog(item) {
      this.removeableChild = item
      this.removeableChildDialog = true
    },
    searchAdvanceShippingNotification(val) {
      if (val) {
        this.searchASN = val
      } else {
        this.searchASN = null
      }
    },
    searchManufacturers(val) {
      if (val) {
        this.searchManufacturer = val
      } else {
        this.searchManufacturer = null
      }
    },
    openRemoveDialog() {
      this.errorMsg = ''
      this.processing = false
      this.removeDialog = true
    },
    openRemoveLineDialog(id) {
      this.removeableLineID = id
      this.processing = false
      this.removeLineDialog = true
    },
    openEditLineDialog(item) {
      this.editedLineItem = this._.cloneDeep(item)
      this.editLineItemDialog = true
    },
    close() {
      this.searchManufacturingOrder = ''
      this.editedItem = this._.cloneDeep(this.asn)
      this.editedLineItem = this._.cloneDeep(this.defaultLineItem)
      this.processing = false
      this.dialog = false
      this.submitDialog = false
      this.reSubmitDialog = false
      this.editLineItemDialog = false
    },
    editASN() {
      this.editedItem = this._.cloneDeep(this.asn)
      this.editedItem.manufacturingOrder = this.manufacturingOrder
      this.editMode = true
      this.dialog = true
    },
    removeASN() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_REMOVE,
          variables: {
            id: this.asn.id
          }
        })
        .then(() => {
          this.processing = false

          this.errorMsg = ''

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'ASN Removed'
          })

          this.$router.back()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    removeASNLine() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_LINE_REMOVE,
          variables: {
            id: this.removeableLineID
          }
        })
        .then(() => {
          this.processing = false

          this.errorMsg = ''

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Line Removed'
          })

          this.$apollo.queries.asn.refetch()
          this.$apollo.queries.parentASN.refetch()

          this.removeableLineID = null
          this.removeLineDialog = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.processing = false

          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'ASN Updated'
          })

          this.$apollo.queries.asn.refetch()
          this.$apollo.queries.parentASN.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    submitASN() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_SUBMIT,
          variables: {
            id: this.asn.id
          }
        })
        .then(() => {
          this.processing = false

          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'ASN Send to Warehouse'
          })

          this.$apollo.queries.asn.refetch()
          this.$apollo.queries.parentASN.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    reSubmitASN() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: ASN_RESUBMIT,
          variables: {
            id: this.asn.id
          }
        })
        .then(() => {
          this.processing = false

          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'ASN Send to Warehouse'
          })

          this.$apollo.queries.asn.refetch()
          this.$apollo.queries.parentASN.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    addProducts() {
      var vm = this
      this.processing = true
      this.errorMsg = ''

      var rowNo = 0

      if (vm.selectedProducts && vm.selectedProducts.length > 0) {
        if (vm.asn.lines?.length > 0) {
          rowNo = vm.asn.lines.length
        }

        vm._.each(vm.selectedProducts, (item, idx) => {
          var data = {
            rowNumber: rowNo.toString(),
            advanceShippingNotificationID: item.advanceShippingNotificationID,
            manufacturingOrderLineID: item.manufacturingOrderLineID,
            seasonProductID: item.seasonProductID,
            productSizeID: item.productSizeID,
            productSizeName: item.productSizeName,
            warehouseID: item.warehouseID,
            barcode: item.barcode.toString(),
            orderedQuantity: item.orderedQuantity,
            shippedQuantity: item.shippedQuantity,
            unitPrice: item.unitPrice
          }

          this.$apollo
            .mutate({
              mutation: ASN_LINE_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              vm.processing = false

              if (vm.selectedProducts.length - 1 === idx) {
                vm.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Product Added'
                })

                vm.selectedProducts = null

                vm.$apollo.queries.asn.refetch()
                this.$apollo.queries.parentASN.refetch()
              }
            })
            .catch(err => {
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  err?.graphQLErrors[0]?.message ||
                  'Something went wrong. Please try again later.'
              })

              this.processing = false
              this.selectedProducts = mdiNumeric9PlusCircle
            })

          rowNo++
        })
      }
    },
    updateLineItem() {
      var vm = this
      this.processing = true
      this.errorMsg = ''

      var data = {
        id: vm.editedLineItem.id,
        rowNumber: vm.editedLineItem.rowNumber,
        notes: vm.editedLineItem.notes,
        shippedQuantity: vm.editedLineItem.shippedQuantity
      }

      this.$apollo
        .mutate({
          mutation: ASN_LINE_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          vm.processing = false

          vm.$store.commit('showSnackbar', {
            active: true,
            message: 'Line Updated'
          })

          vm.$apollo.queries.asn.refetch()
          this.$apollo.queries.parentASN.refetch()

          vm.close()
        })
        .catch(err => {
          vm.$store.commit('showSnackbar', {
            active: true,
            message:
              err?.graphQLErrors[0]?.message ||
              'Something went wrong. Please try again later.'
          })

          this.processing = false
        })
    },
    addChildAsn() {
      var childPosition = this.asn?.children?.length || 0

      var data = {
        id: this.selectedASN.id,
        shippingDate: this.selectedASN.shippingDate,
        deliveryDate: this.selectedASN.deliveryDate,
        notes: this.selectedASN.notes,
        position: childPosition,
        batchASN: false,
        parentID: this.asn.id
      }

      this.$apollo
        .mutate({
          mutation: ASN_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.processing = false

          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Child ASN Added'
          })

          this.selectedASN = null
          this.$apollo.queries.asn.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    removeChild() {
      var data = {
        id: this.removeableChild.id,
        shippingDate: this.removeableChild.shippingDate,
        deliveryDate: this.removeableChild.deliveryDate,
        notes: this.removeableChild.notes,
        position: 0,
        batchASN: false,
        parentID: null
      }

      this.$apollo
        .mutate({
          mutation: ASN_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.processing = false

          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Child ASN Removed'
          })

          if (this.children.length === 1) {
            this.children = []
          } else {
            this._.remove(
              this.children,
              item => item.id === this.removeableChild.id
            )

            this.updatePosition()
          }

          this.removeableChild = null
          this.removeableChildDialog = false
          this.$apollo.queries.asn.refetch()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    updatePosition() {
      var clone = this._.cloneDeep(this.children)
      clone.forEach((item, idx) => {
        var data = {
          id: item.id,
          shippingDate: item.shippingDate,
          deliveryDate: item.deliveryDate,
          notes: item.notes,
          position: idx,
          batchASN: false,
          parentID: item.parentID
        }

        this.$apollo
          .mutate({
            mutation: ASN_UPDATE,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.processing = false

            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Updated ASN'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      })
    }
  }
}
</script>

<style scoped>
.handle {
  cursor: move;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
